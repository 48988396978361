import classNames from 'classnames';
import React, {
  useEffect,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ChatMessages, {
  ChatColor,
  ChatPosition,
} from './ChatMessages/ChatMessages';
import {
  CABA_TIME_FORMAT,
  CHAT_TOPIC_SEPARATOR,
  FLIGHT_CHAT,
  TEAM_CHAT,
} from '../../../../utils/constants';
import { useSendChatMessage } from '../../../../utils/hooks/useSendChatMessage';
import { useDispatch, useSelector } from 'react-redux';
import ChatInput from './ChatInput/ChatInput';
import {
  removeChatMessages,
  selectChatIdByRolesFlight,
  selectConversationById,
  selectLastStatusUpdate,
  updateChatMessages,
  updateMessageStatus,
} from '../../../../redux/reducers/chatReducer';
import { createUniqueId } from '../../../../utils/createUniqueId';
import {
  ChatMessage,
  MessageStatus,
  RoleGroup,
} from '../../../../utils/generated/graphql';
import {
  getCarrierFromFlighId,
  scrollToBottomOfContainer,
  setTimeFormat,
} from '../../../../utils/helpers';
import {
  select24Format,
  selectUTC,
} from '../../../../redux/reducers/settingsReducer';
import { ChatIdState } from '../FlightChatContainer';
import { useGetChatMessagesLazy } from '../../../../utils/hooks/useGetChatMessages';
import { ChatUpdateType } from '../../../../models/ChatUpdateType';
import useNetworkStatus from '../../../../utils/hooks/useNetworkStatus';
import moment from 'moment';

interface IChatWindow {
  className?: string;
  currentUserRole?: string;
  currentUserTechicalRole?: string;
  activeChatTechnicalRole?: string;
  flightId: string;
  chatDetails: ChatIdState;
  currentUserChatName: string;
  markMessagesAsRead: () => void;
}

export enum MessageSending {
  SENDING = 'SENDING',
}
type MessageStatusExtended = MessageSending | MessageStatus;

export interface IMessage {
  conversationId?: string;
  messageId?: string;
  createdAt?: string;
  body?: string;
  createdBy?: string;
  isMe?: boolean;
  userName?: string;
  status?: MessageStatusExtended;
}

export interface IGroupMessessage {
  body: string;
  createdAt: string | null;
  status: string;
  messageId?: string | null;
}

export interface IMappedMessage {
  conversationId: string;
  createdAt: string;
  messageId: string;
  isMe: boolean;
  userName: string;
  messages: IGroupMessessage[];
  status: string;
}

const groupMessages = (
  messages: ChatMessage[],
  isUTC: boolean,
  is24Format: boolean
): IMappedMessage[] => {
  return messages.reduce(
    (result: IMappedMessage[], message: ChatMessage, index, array) => {
      const messagesArray: IGroupMessessage[] = [];
      const createdAt = setTimeFormat({
        getTime: message.createdAt,
        utcFormat: isUTC,
        format24: is24Format,
        getTimeFormat: 'YYYY-MM-DDTHH:mm:ssZ',
        hasAMPM: true,
      });

      if (
        index === 0 ||
        array[index - 1].userName?.toUpperCase() !==
          message.userName?.toUpperCase()
      ) {
        messagesArray.push({
          body: message.body ?? '',
          createdAt: createdAt ?? '',
          status: message.status ?? MessageSending.SENDING,
          messageId: message.messageId,
        });
        result.push({
          messageId: message.messageId ?? '',
          conversationId: message.conversationId ?? '',
          createdAt: createdAt ?? '',
          userName: message.userName ?? '',
          isMe: message.isMe ?? false,
          messages: messagesArray,
          status: message.status ?? MessageSending.SENDING,
        });
        return result;
      }

      const lastChatIndex = result.length - 1;
      const lastChat = result[lastChatIndex];
      lastChat.messages.push({
        body: message.body ?? '',
        createdAt: createdAt ?? '',
        status: message.status ?? MessageSending.SENDING,
        messageId: message.messageId,
      });
      return result;
    },
    []
  );
};

const ChatWindow = ({
  className,
  currentUserRole,
  currentUserTechicalRole,
  activeChatTechnicalRole,
  flightId,
  chatDetails,
  currentUserChatName,
  markMessagesAsRead,
}: IChatWindow) => {
  const chatId = useSelector(
    selectChatIdByRolesFlight(
      currentUserTechicalRole?.toUpperCase() ?? '',
      activeChatTechnicalRole?.toUpperCase() ?? '',
      flightId
    )
  );

  const currentChatId = chatDetails.chatId || chatId;
  const chatMessages = useSelector(selectConversationById(currentChatId));
  const isUTC: boolean = useSelector(selectUTC);
  const is24Format = useSelector(select24Format);
  const { getChatMessages } = useGetChatMessagesLazy();
  const lastStatusUpdate =
    useSelector(selectLastStatusUpdate) ??
    `${moment.utc().subtract(1, 'day').format(CABA_TIME_FORMAT)}Z`;
  const isOnline = useNetworkStatus();

  const groupedMessages = useMemo(() => {
    return groupMessages(chatMessages ?? [], isUTC, is24Format);
  }, [chatMessages, isUTC, is24Format]);

  const id = useId();
  const { onSendChatMessage } = useSendChatMessage();
  const dispatch = useDispatch();

  const retrySendMessage = async () => {
    {
      if (!chatMessages || !chatMessages.length) {
        return;
      }

      const messagesFailed = chatMessages.filter(
        (message) => message.isMe && message.status === MessageStatus.FAILED
      );
      messagesFailed.forEach((message) => deleteChatMessage(message.messageId));

      await Promise.all(
        messagesFailed.map(async (message) => {
          await handleSendMessage(message.body);

          if (!chatMessages?.length) {
            await getChatMessages(
              lastStatusUpdate,
              `TAC_${getCarrierFromFlighId(flightId)}_${
                currentUserRole?.toUpperCase() ?? RoleGroup.BASE
              }`,
              dispatch,
              ChatUpdateType.UPDATED_MESSAGE
            );
          }
        })
      );
    }
  };

  const deleteChatMessage = (messageId) => {
    dispatch(
      removeChatMessages({
        chatId: currentChatId,
        messageId: messageId,
      })
    );
  };

  useLayoutEffect(() => {
    if (isAtBottom) {
      scrollToBottomOfContainer(`messagesContainer${id}`);
    }
  }, [chatMessages]);

  const handleSendMessage = async (message) => {
    let chatIdTemp = currentChatId;
    const splittedTopic = currentChatId?.split(CHAT_TOPIC_SEPARATOR) ?? [];
    const convRolesArray = splittedTopic[0]?.split('-') ?? [];
    const flightId = splittedTopic[1] ?? '';
    const groupChatRole = convRolesArray.find((role) =>
      role.includes(TEAM_CHAT.roleGroup)
    );
    if (groupChatRole) {
      chatIdTemp = `${groupChatRole}${CHAT_TOPIC_SEPARATOR}${flightId}`;
    }
    setIsAtBottom(true);
    try {
      message = message.trim();
      dispatch(
        updateChatMessages({
          chatId: chatIdTemp,
          message: {
            conversationId: chatDetails.lastConversationId ?? null,
            createdAt: new Date(Date.now()).toISOString(),
            body: message,
            createdBy: '',
            isMe: true,
            userName: currentUserChatName,
            status: MessageSending.SENDING,
          },
          flightId,
        })
      );
      const sendMessageResponse = await onSendChatMessage({
        chatId,
        body: message,
        lastConversationId: chatDetails.lastConversationId ?? null,
      });
      if (
        !sendMessageResponse ||
        sendMessageResponse.status === MessageStatus.FAILED ||
        sendMessageResponse.errors?.length
      ) {
        dispatch(
          updateMessageStatus({
            messageId: sendMessageResponse?.messageId || createUniqueId(),
            chatId: chatIdTemp,
            message,
            status: MessageStatus.FAILED,
          })
        );
        return;
      }
      dispatch(
        updateMessageStatus({
          messageId: sendMessageResponse?.messageId,
          chatId: chatIdTemp,
          message,
          status: MessageStatus.SENT,
        })
      );

      if (!chatMessages?.length) {
        await getChatMessages(
          lastStatusUpdate,
          `TAC_${getCarrierFromFlighId(flightId)}_${
            currentUserRole?.toUpperCase() ?? RoleGroup.BASE
          }`,
          dispatch,
          ChatUpdateType.UPDATED_MESSAGE
        );
      }
    } catch (err) {}
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  const [isAtBottom, setIsAtBottom] = useState<boolean>(true);
  useEffect(() => {
    if (groupedMessages.length && isAtBottom) {
      markMessagesAsRead();
    }
  }, [groupedMessages, isAtBottom]);

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      const isBottom = Math.floor(scrollTop + clientHeight) === scrollHeight;
      isBottom !== isAtBottom && setIsAtBottom(isBottom);
    }
  };

  return (
    <div
      className={classNames(
        className,
        'flex flex-col justify-between items-center w-full px-24 mb-16 mobile:mb-32 mobile:px-16'
      )}>
      <div
        onScroll={handleScroll}
        ref={scrollRef}
        id={`messagesContainer${id}`}
        className="w-full py-12 overflow-y-auto">
        {groupedMessages?.map((group) => (
          <ChatMessages
            key={group.createdAt + createUniqueId()}
            color={group.isMe ? ChatColor.Primary : ChatColor.Grey}
            position={group.isMe ? ChatPosition.Right : ChatPosition.Left}
            userName={group.userName}
            createdAt={group.createdAt}
            messages={group.messages}
            retrySendMessage={retrySendMessage}
            deleteChatMessage={deleteChatMessage}
            actionsDisabled={!isOnline}
          />
        ))}
      </div>
      <div className="w-full mobile:mx-8">
        <ChatInput
          className=""
          width="100%"
          onSubmit={handleSendMessage}
          placeholderValue={FLIGHT_CHAT.TYPE}
          containerId={id}
          disabled={!isOnline}
        />
      </div>
    </div>
  );
};

export default ChatWindow;
