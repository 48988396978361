import { useMutation } from '@apollo/client';
import { UPDATE_MY_FLIGHTS } from '../../graphql/myFlights/my-flights.mutations';
import { Mutation, MyFlightUpdateResponse } from '../generated/graphql';
import { IMutationResponse } from '../types';
import { AppDispatch } from '../../redux/store';
import {
  subscribeToFlightsCheckin,
  unsubscribeToFlights,
  unsubscribeToFlightsCheckin,
} from '../notifications/subscribeToFlight';
import { IMyFlightEdit } from '../../pages/Favourites/Favourites';
import {
  addCheckedinUser,
  deleteCheckinUser,
} from '../../redux/reducers/checkinReducer';
import { GET_CHECKED_IN_ROLES } from '../../graphql/checkInFlight/checked-in-roles.queries';
import {
  subscribeToHighlander,
  unsubscribeToHighlander,
} from '../notifications/subscribeToHighlander';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../redux/reducers/authReducer';
import { selectUTC } from '../../redux/reducers/settingsReducer';

interface UpdateMyFlightsResponse
  extends IMutationResponse<MyFlightUpdateResponse[] | null> {
  onUpdateFlights: (
    flights: IMyFlightEdit[],
    dispatch?: AppDispatch
  ) => Promise<MyFlightUpdateResponse[] | null>;
}

export const useUpdateMyFlights = (): UpdateMyFlightsResponse => {
  const [updateFlights, { data, error, loading }] =
    useMutation<Pick<Mutation, 'updateMyFlight'>>(UPDATE_MY_FLIGHTS);
  const userId = useSelector(selectUserId);
  const useUTC = useSelector(selectUTC);
  const onUpdateFlights = async (
    flights: IMyFlightEdit[],
    dispatch?: AppDispatch
  ) => {
    try {
      const response = await updateFlights({
        variables: {
          myFlights: { flights },
        },
        refetchQueries: [GET_CHECKED_IN_ROLES],
      });
      if (response?.data?.updateMyFlight?.updatedFlights) {
        if (!dispatch)
          return response.data.updateMyFlight?.updatedFlights ?? null;

        const checkedInFlights =
          response.data.updateMyFlight.updatedFlights.filter(
            (flight) => flight.isCheckedIn
          );
        subscribeToFlightsCheckin(
          checkedInFlights.map((flight) => ({ flightId: flight.flightId }))
        );
        subscribeToHighlander(
          checkedInFlights
            .filter((flight) => flight.role?.isHighlander)
            .map((flight) => ({
              flightId: flight.flightId,
              userId,
              dispatch,
              isUTC: useUTC,
            }))
        );

        const checkedOutFlightIds = response.data.updateMyFlight.updatedFlights
          .filter((flight) => !flight.isCheckedIn)
          .map((flight) => flight.flightId);

        unsubscribeToFlightsCheckin(checkedOutFlightIds);
        unsubscribeToHighlander(checkedOutFlightIds, userId);

        const unFavFlight = response.data.updateMyFlight.updatedFlights.filter(
          (flight) => !flight.isFavourite
        );

        unsubscribeToFlights(unFavFlight.map((flight) => flight.flightId));

        response.data.updateMyFlight.updatedFlights.forEach((flight) => {
          const flightId = flight.flightId;
          if (flight.isCheckedIn) {
            dispatch(
              addCheckedinUser({
                [flightId]: flight.checkedInRoles,
              })
            );
          } else {
            dispatch(deleteCheckinUser(flightId));
          }
        });

        return response.data.updateMyFlight?.updatedFlights ?? null;
      }

      return null;
    } catch (error) {
      return null;
    }
  };
  return {
    onUpdateFlights,
    data: data?.updateMyFlight?.updatedFlights ?? null,
    error: error,
    loading,
  };
};
