import React from 'react';
import NotificationForm, {
  ISubmitFields,
} from '../../components/AdminNotificationCenter/NotificationForm/NotificationForm';
import { DELETE_NOTIFICATION_COPY } from '../constants';
import { Notification } from '../generated/graphql';

export enum NotificationModalVariant {
  CREATE,
  EDIT,
  DELETE,
}

export const renderNotificationModal = (
  variant: NotificationModalVariant | null,
  notification?: Notification,
  onCreate?: (elem: ISubmitFields) => void,
  onEdit?: (elem: ISubmitFields) => void,
  setCanSubmit?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (variant === NotificationModalVariant.DELETE) {
    return (
      <h1 className="flex justify-center items-center text-16 h-64 text-primary">
        {DELETE_NOTIFICATION_COPY}
      </h1>
    );
  }
  if (variant === NotificationModalVariant.EDIT) {
    return (
      <NotificationForm
        handleSubmit={onEdit}
        notification={notification}
        setCanSubmit={setCanSubmit}
      />
    );
  }

  if (variant === NotificationModalVariant.CREATE) {
    return (
      <NotificationForm handleSubmit={onCreate} setCanSubmit={setCanSubmit} />
    );
  }
};
