import { EMPTY_STATE, N_A } from './constants';
import { IFlightNumber } from './generated/graphql';

export const calculateFlightNumber = (
  isUTC: boolean,
  flightNumberObject: IFlightNumber,
  customEmptyState?: string
) => {
  const emptyState = customEmptyState ?? EMPTY_STATE;

  if (isUTC) {
    return flightNumberObject?.utc ?? emptyState;
  } else {
    return flightNumberObject?.local ?? emptyState;
  }
};
