import React, { useState, useEffect } from 'react';
import SearchInput from '../../SearchInput/SearchInput';
import FlightItem from '../FlightItem/FlightItem';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {
  FLIGHT_SEARCH,
  ERRORS,
  MOBILE_BREAKPOINT,
} from '../../../utils/constants';
import { useFlightsPagination } from '../../../utils/hooks/useFlightsPagination';
import classNames from 'classnames';
import { IFlight } from '../types';
import {
  AutoSizer,
  List,
  CellMeasurer,
  CellMeasurerCache,
} from 'react-virtualized';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';
import FeatureFlagUtil from '../../../utils/FeatureFlagUtil';
import Drawer from '../../Drawer/Drawer';
import SearchFilterOverlayContainer from '../../organisms/SearchFilterOverlay/SearchFilterOverlayContainer';

interface IFlightSearch {
  flightsList: IFlight[];
  onSearch: (value: string | null) => void;
  addToFavourites: (id: string[], genericQuery: string) => Promise<string[]>;
  removeFromFavourites: (
    id: string[],
    genericQuery: string
  ) => Promise<string[]>;
  searchQuery: string;
  isLoading: boolean;
}

const selectBtnContainerClassNames =
  'flex justify-between items-start mt-32 mb-8 mobile:mt-8 !w-full mobile:w-[100%] mobile:pl-[24px]  mobile:pr-[6px]';
const paginationBtnClassNames =
  'flex justify-center items-center bg-white h-44 w-[177px] rounded-4 dark:text-grey-12 text-primary';
const paginationBtnTextClassNames =
  'font-body-bold w-[121px] text-primary text-14 dark:text-grey-80 leading-[18px]';
const selectBtnTextClassNames =
  'font-body-text text-primary text-14 underline dark:text-grey-12 w-[55px] mr-6';
const noFlightsClassNames = 'font-head-bold text-grey-25 text-24';

const ROW_HEIGHT_TABLET = 92;
const FIRST_ROW_HEIGHT_MOBILE = 50;
const FLIGHT_SEARCH_HEADER_HEIGHT = 149;
const ROW_HEIGHT_MOBILE = 156;

const cacheList = new CellMeasurerCache({
  minWidth: ROW_HEIGHT_TABLET,
  fixedHeight: false,
});

const FlightSearch = ({
  flightsList = [],
  onSearch,
  addToFavourites,
  removeFromFavourites,
  searchQuery,
  isLoading,
  ...others
}: IFlightSearch) => {
  const [resetToDefault, setResetToDefault] = useState(false);
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_BREAKPOINT
  );
  const [calculatedHeight, setCalculatedHeight] = useState(
    window.innerHeight - FLIGHT_SEARCH_HEADER_HEIGHT
  );

  const [isFilterOverlayOpen, setIsFilterOverlayOpen] =
    useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState(true);

  const isOnline = useNetworkStatus();

  const { visibleFlights, setEarlierFlights, displayEarlierBtn } =
    useFlightsPagination(
      flightsList,
      resetToDefault,
      setResetToDefault,
      isLoading
    );

  const earlierButtonClasses = classNames(paginationBtnClassNames, {
    invisible: !displayEarlierBtn,
  });

  const areAllFlightsFavourite = visibleFlights
    ?.filter((value) => Object.keys(value).length !== 0)
    ?.every((flight: IFlight) => flight?.isFavourite);

  const getButtonText = () =>
    areAllFlightsFavourite
      ? FLIGHT_SEARCH.UNSELECT_ALL
      : FLIGHT_SEARCH.SELECT_ALL;

  const handleAllFlights = async (flightIds: string[]) =>
    areAllFlightsFavourite
      ? removeFromFavourites(flightIds, searchQuery)
      : addToFavourites(flightIds, searchQuery);

  const selectButtonHandler = async () => {
    const closestIndex = visibleFlights.findIndex((flight: IFlight) => {
      return flight.closestDeparture === true;
    });
    const earlierFlights = visibleFlights.slice(0, closestIndex);
    const nextFlights = visibleFlights.slice(
      closestIndex,
      visibleFlights.length
    );

    const finalArray = nextFlights.concat(earlierFlights);
    const flightIds = finalArray
      .filter((flight) => Object.keys(flight).length)
      .map((flight: IFlight) => flight.flightId);

    await handleAllFlights(flightIds);
  };

  const openFilterOverlay = (isOpen: boolean): void => {
    setIsFilterOverlayOpen(isOpen);
  };

  const applyFilter = (isApplied: boolean): void => {
    setIsFilterApplied(isApplied);
  };

  const onFilterOpen = () => {
    if (isFilterApplied) {
      setIsFilterApplied(false);
    } else {
      setIsFilterOverlayOpen(true);
    }
  };

  const onClose = () => {
    setIsFilterOverlayOpen(false);
    setIsFilterApplied(false);
  };

  const renderFilterButton = () => {
    return (
      <Button
        Icon={
          <Icon
            variant={
              isFilterApplied ? 'searchFilterActive' : 'searchFilterInactive'
            }
            width={40}
            height={40}
            className={classNames({
              'bg-white': !isFilterApplied,
              'bg-secondary': isFilterApplied,
            })}
          />
        }
        onClick={onFilterOpen}
      />
    );
  };

  const renderEarlierBtn = () => (
    <Button
      className={earlierButtonClasses}
      text={FLIGHT_SEARCH.EARLIER_FLIGHTS}
      textClassName={paginationBtnTextClassNames}
      Icon={
        <Icon
          width={14}
          height={15}
          variant="arrowUp"
          className="inline mr-10 dark:fill-grey-80"
        />
      }
      onClick={setEarlierFlights}
    />
  );

  const renderEarlierBtnMobile = () => (
    <Button
      className={classNames({
        invisible: !displayEarlierBtn,
      })}
      text={FLIGHT_SEARCH.EARLIER_FLIGHTS}
      textClassName={selectBtnTextClassNames}
      Icon={
        <Icon
          width={12}
          height={12}
          variant="arrowUp"
          className="inline mr-10 dark:fill-grey-80"
        />
      }
      onClick={setEarlierFlights}
    />
  );

  const rowRenderer = ({ index, key, style, width, parent }) => {
    const flight = index !== 0 ? visibleFlights[index] : null;
    return (
      <CellMeasurer
        cache={cacheList}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}>
        {() => (
          <div key={key} style={style}>
            {index === 0 ? (
              <div className={selectBtnContainerClassNames} style={{ width }}>
                {!isMobile && <div className="w-[55px]"></div>}
                {isMobile ? renderEarlierBtnMobile() : renderEarlierBtn()}
                <Button
                  text={getButtonText()}
                  textClassName={selectBtnTextClassNames}
                  onClick={selectButtonHandler}
                  disabled={!isOnline}
                  className={classNames({ 'opacity-50': !isOnline })}
                />
              </div>
            ) : (
              <div className="overflow-y-hidden mx-24 mobile:mx-0">
                <FlightItem
                  {...flight}
                  setUpdatedFlights={() =>
                    flight?.isFavourite
                      ? removeFromFavourites([flight.flightId], searchQuery)
                      : addToFavourites([flight?.flightId], searchQuery)
                  }
                  onFavouritePress={() => {
                    flight?.isFavourite
                      ? removeFromFavourites([flight.flightId], searchQuery)
                      : addToFavourites([flight?.flightId], searchQuery);
                  }}
                />
              </div>
            )}
          </div>
        )}
      </CellMeasurer>
    );
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    setCalculatedHeight(window.innerHeight - FLIGHT_SEARCH_HEADER_HEIGHT);
    cacheList.clearAll();
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
    setCalculatedHeight(window.innerHeight - FLIGHT_SEARCH_HEADER_HEIGHT);
    window.visualViewport
      ? window.visualViewport?.addEventListener('resize', handleResize)
      : window.addEventListener('resize', handleResize);
    return () => {
      window.visualViewport
        ? window.visualViewport?.removeEventListener('resize', handleResize)
        : window.removeEventListener('resize', handleResize);
    };
  }, []);

  const renderFlightList = () => {
    return visibleFlights?.length ? (
      <div className="flex flex-col gap-16">
        <AutoSizer disableHeight>
          {({ width }) => {
            return (
              <>
                <List
                  rowCount={visibleFlights.length}
                  width={width}
                  height={calculatedHeight}
                  rowHeight={({ index }) => {
                    if (isMobile) {
                      return index === 0
                        ? FIRST_ROW_HEIGHT_MOBILE
                        : ROW_HEIGHT_MOBILE;
                    }
                    return ROW_HEIGHT_TABLET;
                  }}
                  rowRenderer={({ index, key, style, parent }) =>
                    rowRenderer({ index, key, style, width, parent })
                  }
                />
              </>
            );
          }}
        </AutoSizer>
      </div>
    ) : (
      <div className="flex flex-col items-center justify-between grow">
        <div
          className={classNames({
            'w-[100%] pl-[1.5rem] pb-1': isMobile,
          })}>
          {displayEarlierBtn &&
            (isMobile ? renderEarlierBtnMobile() : renderEarlierBtn())}
        </div>
        <div className={noFlightsClassNames}>{FLIGHT_SEARCH.NO_FLIGHTS}</div>
        <div></div>
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full" {...others}>
      <div className="px-24 flex gap-1 pb-[2rem]">
        <SearchInput
          key={searchQuery}
          onSubmit={onSearch}
          placeholderValue="Search for e.g. LH400, VIEBRU, HB-JNA"
          className="pr-40"
          setResetToDefault={setResetToDefault}
          defaultValue={searchQuery}
          error={
            searchQuery && searchQuery.length > 0 && searchQuery.length < 3
              ? ERRORS.MIN_3_CHAR
              : null
          }
          disabled={!isOnline}
        />

        {FeatureFlagUtil.showFeature(
          process?.env?.REACT_APP_TAC_FLIGHT_SEARCH_FILTER_FEATURE_DISABLED ??
            '',
          []
        ) && renderFilterButton()}
      </div>
      {isLoading ? <LoadingSpinner /> : renderFlightList()}

      {FeatureFlagUtil.showFeature(
        process?.env?.REACT_APP_TAC_FLIGHT_SEARCH_FILTER_FEATURE_DISABLED ?? '',
        []
      ) && (
        <Drawer
          drawerId="filter-overlay"
          className="p-[16px] relative"
          isOpen={isFilterOverlayOpen}
          onClose={onClose}>
          <SearchFilterOverlayContainer
            isLoading={isLoading}
            openFilterOverlay={openFilterOverlay}
            applyFilter={applyFilter}
          />
        </Drawer>
      )}
    </div>
  );
};

export default FlightSearch;
