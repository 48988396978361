import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  AUTHENTICATING,
  GREETING,
  IS_EVENING_HOURS,
  IS_MORNING_HOURS,
  IS_NOON_HOURS,
  LOGIN_COPY,
  REDIRECTING,
} from '../../utils/constants';
import LoginButton from '../../components/CustomButtons/LoginButton/LoginButton';
import {
  login,
  selectError,
  selectIsFirstLogin,
  selectIsLoggedIn,
  selectIsLoginLoading,
} from '../../redux/reducers/authReducer';
import NotificationsList from '../../components/NotificationsList/NotificationsList';
import { generateVerifier, generateChallenge } from '../../utils/helpers';
import StorageUtil from '../../utils/StorageUtil';
import AppVersion from '../../components/AppVersion/AppVersion';
import PullToRefresh from '../../PullToRefresh';
import { useUpdateUserSettings } from '../../utils/hooks/useUpdateUserSettings';
import { useGetUserSettingsLazy } from '../../utils/hooks/useGetUserSettings';

const loginContainerClass =
  'flex flex-col justify-end mobile:justify-between mobile:self-center h-[100%] ml-80 mobile:ml-0 mobile:mt-80 mobile:mb-80 mb-80';

const Login = ({ ...others }) => {
  const hours = new Date().getHours();
  const greeting = `${GREETING(hours)}!`;
  const loginWrapper = classNames('fixed inset-0');
  const dispatch = useDispatch();
  const { onUpdateUserSettings } = useUpdateUserSettings();
  const { getUserSettings } = useGetUserSettingsLazy();

  const [searchParams] = useSearchParams();
  const [codeVerifier, setCodeVerifier] = useState(
    StorageUtil.get('codeVerifier')
  );
  const isLoading = useSelector(selectIsLoginLoading);
  const error = useSelector(selectError);
  const [challenge, setChallenge] = useState({});

  const code = searchParams.get('code');

  if (!codeVerifier && !code) {
    const generatedVerifierCode = generateVerifier();
    StorageUtil.set('codeVerifier', generatedVerifierCode);
    setCodeVerifier(generatedVerifierCode);
  }

  useEffect(() => {
    (async () => {
      setChallenge(await generateChallenge(codeVerifier));
    })();

    if (code && !isLoading) {
      dispatch(
        login({ code, codeVerifier, onUpdateUserSettings, getUserSettings })
      );
    }
  }, []);

  const getAuthorizationURL = () => {
    const authorizationEndpoint = process?.env?.REACT_APP_TAC_AUTHORIZATION_URL;
    const clientId = process?.env?.REACT_APP_TAC_AUTHORIZATION_CLIENT_ID;
    const redirectUri =
      window.location.origin ??
      process?.env?.REACT_APP_TAC_AUTHORIZATION_REDIRECT_URL;
    const responseType =
      process?.env?.REACT_APP_TAC_AUTHORIZATION_RESPONSE_TYPE;
    const scope = process?.env?.REACT_APP_TAC_AUTHORIZATION_SCOPE;

    return `${authorizationEndpoint}?scope=${scope}&client_id=${clientId}&redirect_uri=${redirectUri}&code_challenge=${challenge}&code_challenge_method=S256&response_type=${responseType}`;
  };

  const greetingClass = classNames(
    'leading-[42px] text-42 font-[700] font-head-bold',
    {
      'text-primary dark:text-primary': IS_NOON_HOURS(hours),
      'text-white dark:text-white': !IS_NOON_HOURS(hours),
      'mobile:text-primary mobile:dark:text-primary': IS_MORNING_HOURS(hours),
    }
  );

  const copyClass = classNames(
    'leading-[42px] text-41 font-[250] font-head-light mb-40',
    {
      'text-primary dark:text-primary': IS_NOON_HOURS(hours),
      'text-white dark:text-white': !IS_NOON_HOURS(hours),
      'mobile:text-primary mobile:dark:text-primary': IS_MORNING_HOURS(hours),
    }
  );
  const loginPageClass = classNames(
    'flex flex-col justify-end h-[100%] bg-cover bg-center',
    {
      'bg-morning': IS_MORNING_HOURS(hours),
      'bg-day': IS_NOON_HOURS(hours),
      'bg-evening': IS_EVENING_HOURS(hours),
    }
  );
  const getCopy = () => {
    if (code) {
      if (isLoading) {
        return AUTHENTICATING;
      }
      if (error) {
        return error;
      }
      return REDIRECTING;
    }
    return LOGIN_COPY;
  };

  return (
    <PullToRefresh>
      <div className={loginWrapper}>
        <div className="absolute w-fit left-1/2 -translate-x-1/2 mt-24 z-50">
          <NotificationsList />
        </div>
        <div className={loginPageClass} {...others}>
          <div className={loginContainerClass}>
            <div>
              <h1 className={greetingClass}>{greeting}</h1>
              <h2 className={copyClass}>{getCopy()}</h2>
            </div>
            {!code && (
              <LoginButton
                isLoading={isLoading}
                authorizationUrl={getAuthorizationURL()}
              />
            )}
          </div>
          <AppVersion isHidden={false} isLoginPage={true} />
        </div>
      </div>
    </PullToRefresh>
  );
};

export default Login;
