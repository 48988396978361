import React from 'react';
import Button from '../../Button/Button';
import { FLIGHT_SEARCH_FILTER } from '../../../utils/constants';
import classNames from 'classnames';
import Checkbox from '../../atoms/checkbox/Checkbox';

import Title from '../../Title/Title';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';

interface IFilterValue {
  id: string;
  label: string;
  isDefault: boolean;
}
export interface ISearchFilterGroup {
  category: string;
  label: string;
  cols: 'one' | 'two';
  values: IFilterValue[];
}
interface ISearchFilterOverlay {
  openFilterOverlay: (isOpen: boolean) => void;
  applyFilter: (isApplied: boolean) => void;
  isLoading: boolean;
  filters: ISearchFilterGroup[];
}

const selectBtnTextClassNames =
  'font-body-text text-primary text-14 underline dark:text-grey-12 w-[55px]';
const buttonClassName =
  'font-head-bold flex items-center justify-center rounded-4 grow';

const btnClass = classNames(
  buttonClassName,
  'flex h-44 bg-primary dark:bg-grey-12 '
);

const gridStructure = {
  one: 'grid-cols-[repeat(5,4.7rem)] tablet:grid-cols-[repeat(5,4.7rem)] laptopL:grid-cols-[repeat(5,5.7rem)]',
  two: 'grid-cols-[repeat(2,9.5rem)] tablet:grid-cols-[repeat(2,9.5rem)] laptopL:grid-cols-[repeat(2,11.5rem)]',
};

const SearchFilterOverlay = ({
  isLoading,
  openFilterOverlay,
  applyFilter,
  filters,
}: ISearchFilterOverlay) => {
  const onApplyFilter = () => {
    applyFilter(true);
    openFilterOverlay(false);
  };

  const onCancel = () => {
    openFilterOverlay(false);
    applyFilter(false);
  };

  const onResetFilter = () => {};

  const onSaveFilter = () => {
    applyFilter(true);
    openFilterOverlay(false);
  };

  return (
    <div className="p-[24px]">
      <div className="flex items-center justify-between">
        <span className="tfont-body-text text-12 leading-[12px] text-grey-40 font-semibold text-14 font-body-text dark:text-grey-12 uppercase">
          {FLIGHT_SEARCH_FILTER.TITLE}
        </span>

        <Button
          text={FLIGHT_SEARCH_FILTER.CANCEL}
          className="w-[110px] bg-grey-12 dark:bg-white rounded-4 h-44 flex items-center justify-center"
          textClassName="text-14 !text-primary font-body-text leading-[18px]"
          onClick={onCancel}
        />
      </div>

      {filters.map((item) => {
        return (
          <div className="mt-48">
            <div className="flex justify-between items-center">
              <Title title={item.label} className="font-bold" />
              <div className="flex gap-5">
                <Button
                  text={FLIGHT_SEARCH_FILTER.SELECT_ALL}
                  textClassName={selectBtnTextClassNames}
                  onClick={() => {}}
                />
                <Button
                  text={FLIGHT_SEARCH_FILTER.DESELECT_ALL}
                  textClassName={selectBtnTextClassNames}
                  onClick={() => {}}
                />
              </div>
            </div>
            <div
              className={`grid gap-y-2 mt-12 ${
                gridStructure[item.cols ?? 'one']
              }`}>
              {item.values.map((filter) => {
                return (
                  <Checkbox label={filter.label} checked={filter.isDefault} />
                );
              })}
            </div>
          </div>
        );
      })}

      <div className="flex gap-4 absolute bottom-0 left-0 pb-32 pl-32 pr-32 w-[100%]">
        <Button
          text={FLIGHT_SEARCH_FILTER.RESET}
          textClassName={'text-primary text-14 dark:text-grey-12 '}
          className={buttonClassName}
          onClick={onResetFilter}
        />
        <Button
          text={
            isLoading ? (
              <LoadingSpinner width={24} height={24} />
            ) : (
              FLIGHT_SEARCH_FILTER.SAVE_AS_DEFAULT
            )
          }
          textClassName={'text-white text-14 dark:text-grey-90'}
          className={btnClass}
          onClick={onSaveFilter}
          disabled={false}
        />

        <Button
          text={
            isLoading ? (
              <LoadingSpinner width={24} height={24} />
            ) : (
              FLIGHT_SEARCH_FILTER.APPLY
            )
          }
          textClassName={'text-white text-14 dark:text-grey-90'}
          className={btnClass}
          onClick={onApplyFilter}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default SearchFilterOverlay;
