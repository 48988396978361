import React, { useState, useRef, useEffect } from 'react';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import {
  CABA_TIME_FORMAT,
  CONFIRM,
  DISMISS,
  FLIGHT_DETAILS_HEADER,
  HIGHLANDER_MODAL_FLIGHTS_DETAILS,
  HIGHLANDER_MODAL_MY_FLIGHTS,
  PROCEED,
} from '../../../utils/constants';
import { useClickOutside } from '../../../utils/hooks/useClickOutside';
import { useCheckIn } from '../../../utils/hooks/useCheckIn';
import { useCheckOut } from '../../../utils/hooks/useCheckOut';
import { setIsCheckIn } from '../../../redux/reducers/headerReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPhone,
  selectRoles,
} from '../../../redux/reducers/settingsReducer';
import {
  CheckedInResponsableRole,
  CheckInSource,
  CheckInUserType,
  Permission,
  Role,
} from '../../../utils/generated/graphql';
import {
  addCheckedinUser,
  deleteCheckinUser,
} from '../../../redux/reducers/checkinReducer';
import { selectLastStatusUpdate } from '../../../redux/reducers/chatReducer';
import { useGetChatMessagesLazy } from '../../../utils/hooks/useGetChatMessages';
import { ChatUpdateType } from '../../../models/ChatUpdateType';
import SingleSelectDropdown from '../../SingleSelectDropdown/SingleSelectDropdown';
import { getCarrierFromFlighId, hasPermissions } from '../../../utils/helpers';
import { CheckType } from '../../../utils/hooks/useCheckPermissions';
import Modal from '../../Modal/Modal';
import { useGetCheckedInRolesLazy } from '../../../utils/hooks/useGetCheckedInRoles';
import { selectUserId } from '../../../redux/reducers/authReducer';
import moment from 'moment';

const ROLE_GROUP_CABIN = 'CABIN';

interface ICheckinDropdown {
  flightNumber: string;
  flightId: string;
  checkedInRole?: string | null;
}

interface ICheckinContainer extends ICheckinDropdown {
  variant?: string;
  className?: string;
  editModeEnabled?: boolean;
  onEdit?: (role: string | null) => void;
  disabled?: boolean;
  setIsColored?: any;
  isMyFlightsPage?: boolean;
}

const calculateDropdownPosition = (
  clickOutsideRef: React.RefObject<HTMLButtonElement>
) => {
  const buttonRect = clickOutsideRef?.current?.getBoundingClientRect();
  const windowHeight = window.innerHeight;
  if (buttonRect) {
    //check if remaining space below the button is less than 1/3 of the window height
    return windowHeight - buttonRect.bottom < windowHeight / 3
      ? 'above'
      : 'below';
  }
  return '';
};

const filterRoles = (
  roles: Role[],
  carrierCode: string,
  rolesStore: Role[]
) => {
  return roles
    .filter((role, index, array) => {
      const CABIN_FA = `TAC_${role.carrier}_CABIN_FA`;

      const hasDuplicate = array.some((duplicateRole, duplicateIndex) => {
        return (
          duplicateIndex !== index &&
          role.roleGroup === ROLE_GROUP_CABIN &&
          duplicateRole.carrier === role.carrier
        );
      });

      const filteredDuplicatedCabin =
        !hasDuplicate || role.technicalRole !== CABIN_FA;
      return filteredDuplicatedCabin && role.carrier === carrierCode;
    })
    .filter((role) => {
      const roleHasCheckInPermission: boolean = hasPermissions(
        rolesStore,
        [Permission.CHECK_IN],
        CheckType.PARTIAL,
        carrierCode,
        role._id
      );

      return roleHasCheckInPermission && role;
    });
};

const isHighlanderAlreadyCheckedIn = (
  checkedinRoles: CheckedInResponsableRole[],
  selectedRole: Role
) =>
  !!checkedinRoles.find((role) => role.roleGroup === selectedRole.roleGroup)
    ?.isHighlander;

const CheckinContainer = ({
  flightNumber,
  flightId,
  checkedInRole,
  variant = 'default',
  className,
  editModeEnabled = false,
  onEdit,
  disabled,
  setIsColored,
  isMyFlightsPage,
}: ICheckinContainer) => {
  const { loading: checkInLoading, onCheckIn } = useCheckIn();
  const { onCheckOut, loading: checkOutLoading } = useCheckOut();
  const { getChatMessages } = useGetChatMessagesLazy();
  const lastStatusUpdate =
    useSelector(selectLastStatusUpdate) ??
    `${moment.utc().subtract(1, 'day').format(CABA_TIME_FORMAT)}Z`;
  const { getCheckInRoles, loading: checkedinRolesLoading } =
    useGetCheckedInRolesLazy();
  const isCheckInOutLoading =
    checkInLoading || checkOutLoading || checkedinRolesLoading;

  const [checkInOutText, setCheckInOutText] = useState<
    string | React.ReactNode
  >(FLIGHT_DETAILS_HEADER.CHECK_IN);

  const [hasSelected, setHasSelected] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const rolesStore = useSelector(selectRoles) ?? [];
  const userId = useSelector(selectUserId);
  const carrierCode = flightNumber?.slice(0, 2) || '';
  const filteredRoles = filterRoles(rolesStore, carrierCode, rolesStore) ?? [];
  const clickOutsideRef = useRef<HTMLButtonElement>(null);
  const dropdownPosition = calculateDropdownPosition(clickOutsideRef);
  const isDisabled = disabled || !filteredRoles?.length || isCheckInOutLoading;
  const phoneNumber = useSelector(selectPhone);

  const [highlanderModalConfig, setHighlanderModalConfig] = useState<{
    showModal: boolean;
    selectedRole: Role;
    previousText: string;
  }>({
    showModal: false,
    selectedRole: null,
    previousText: '',
  });

  useClickOutside(clickOutsideRef, () => {
    setIsOpen((prevIsOpen) => {
      if (prevIsOpen) {
        return false;
      }
      return prevIsOpen;
    });
  });

  useEffect(() => {
    if (checkedInRole) {
      const role = filteredRoles.find((role) => role._id === checkedInRole);
      const roleName = role?.name;
      const roleId = role?._id ?? null;
      if (roleName) {
        setCheckInOutText(roleName);
        setHasSelected(roleId);
      }
    } else if (!checkedInRole) {
      setCheckInOutText(FLIGHT_DETAILS_HEADER.CHECK_IN);
      setHasSelected(null);
    }
  }, [checkedInRole]);

  useEffect(() => {
    if (isCheckInOutLoading) {
      setCheckInOutText(<LoadingSpinner width={32} height={32} />);
    }
  }, [isCheckInOutLoading]);

  const handleCheckOperations = async (e, prevText) => {
    if (filteredRoles.length === 1 && !hasSelected) {
      if (typeof checkInOutText === 'string') {
        handleRoleSelection(filteredRoles[0], checkInOutText);
      }
      return;
    }
    if (!isOpen && !hasSelected) {
      e.preventDefault();
      setIsOpen(true);
    }
    if (hasSelected) {
      try {
        if (!editModeEnabled) {
          const isHiglander =
            filteredRoles?.find((role) => role._id === hasSelected)
              ?.isHighlander ?? false;
          await onCheckOut(
            {
              checkOutInput: {
                flightId,
                source: CheckInSource.INTERNAL,
                checkInUserType: CheckInUserType.TAC,
              },
            },
            isHiglander,
            userId
          );

          dispatch(setIsCheckIn(false));
          dispatch(deleteCheckinUser(flightId));
        } else {
          onEdit?.(null);
        }
        setCheckInOutText(FLIGHT_DETAILS_HEADER.CHECK_IN);
        setHasSelected(null);
      } catch {
        setCheckInOutText(prevText);
      }
      return;
    }
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const setShowModal = (value: boolean) =>
    setHighlanderModalConfig((prev) => ({
      ...prev,
      showModal: value,
    }));

  const handleRoleSelection = async (role: Role, prevText) => {
    setIsOpen(false);
    const roles = (await getCheckInRoles(flightId))?.[0]?.roles;
    setHighlanderModalConfig((prev) => ({
      ...prev,
      selectedRole: role,
      previousText: prevText,
    }));
    if (isHighlanderAlreadyCheckedIn(roles, role)) {
      setShowModal(true);
    } else {
      await handleConfirmCheckin(role, prevText);
    }
  };

  const handleCheckin = async (role: Role) => {
    const checkedinData = await onCheckIn(
      {
        checkInInput: {
          flightId,
          role: role._id,
          source: CheckInSource.INTERNAL,
          checkInUserType: CheckInUserType.TAC,
          phone: phoneNumber,
        },
      },
      dispatch,
      role.isHighlander
    );
    setIsColored(true);

    if (checkedinData) {
      dispatch(
        addCheckedinUser({
          [flightId]: {
            roles: checkedinData.checkedInRoles,
            myRole: checkedinData.flight?.role,
          },
        })
      );
      getChatMessages(
        lastStatusUpdate,
        `TAC_${getCarrierFromFlighId(flightId)}_${role.roleGroup}`,
        dispatch,
        ChatUpdateType.NEW_MESSAGE
      );
    } else {
      setCheckInOutText(FLIGHT_DETAILS_HEADER.CHECK_IN);
      return;
    }
  };

  const handleConfirmCheckin = async (selectedRole: Role, previousText) => {
    try {
      setShowModal(false);
      if (!editModeEnabled) {
        await handleCheckin(selectedRole);
      } else {
        onEdit?.(selectedRole._id);
      }
      setCheckInOutText(selectedRole.name);
      setHasSelected(selectedRole._id);
      dispatch(setIsCheckIn(true));
    } catch {
      setCheckInOutText(previousText);
    }
  };

  return (
    <>
      <SingleSelectDropdown<Role>
        displayText={checkInOutText}
        isDisabled={!!isDisabled}
        handleDropdownClick={handleCheckOperations}
        showPulseNotification={
          editModeEnabled
            ? !!(checkedInRole && checkedInRole === hasSelected)
            : !!(hasSelected && !isCheckInOutLoading)
        }
        isOpen={isOpen}
        clickOutsideRef={clickOutsideRef}
        listItems={filteredRoles}
        handleItemSelection={handleRoleSelection}
        variant={variant}
        className="bg-white relative flex-1"
        dropdownPosition={dropdownPosition}
      />
      <Modal
        title=""
        showModal={highlanderModalConfig.showModal}
        setShowModal={setShowModal}
        onSubmit={() =>
          handleConfirmCheckin(
            highlanderModalConfig.selectedRole,
            highlanderModalConfig.previousText
          )
        }
        submitText={isMyFlightsPage ? PROCEED : CONFIRM}
        cancelText={DISMISS}
        onCancel={() => {
          setShowModal(false);
          setCheckInOutText(FLIGHT_DETAILS_HEADER.CHECK_IN);
        }}
        showControls={true}
        exitButton={false}>
        <div className="flex flex-col gap-y-6 items-center justify-center pb-[50px]">
          {isMyFlightsPage
            ? HIGHLANDER_MODAL_MY_FLIGHTS
            : HIGHLANDER_MODAL_FLIGHTS_DETAILS}
        </div>
      </Modal>
    </>
  );
};

export default CheckinContainer;
