import React, { useEffect, useRef, useState } from 'react';
import Badge from '../Badge/Badge';
import Icon from '../Icon/Icon';
import classNames from 'classnames';

const GAP_SIZE = 16;

interface Tag {
  isWarning: boolean;
  title: string;
}

export interface BadgeData {
  value: number;
  max: number;
  tagArray: Tag[];
  tagsShown: number;
}

interface BadgesContainerProps {
  badgeData: BadgeData;
  onClick?: () => void;
  id?: string;
  isLoading: boolean;
}

const BadgesContainer: React.FC<BadgesContainerProps> = ({
  badgeData,
  onClick,
  id,
  isLoading,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const badgeRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const [rows, setRows] = useState<Tag[][]>([[], []]);
  // used to prevent flickering
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  const tagTextClass = 'font-body-text dark:text-grey-12 text-primary';
  const tagClass = 'dark:bg-grey-100 bg-grey-6 max-w-[200px]';
  const iconClass = 'dark:fill-grey-12 fill-primary';
  const rowContainerClass =
    'flex items-center justify-center flex-wrap gap-[16px]';

  let badgesContainerClass = classNames('w-full flex flex-col gap-[8px]', {
    'pt-[24px] ': id === 'baggages_tags' && badgeData?.tagArray?.length > 0,
  });

  const warningIcon = (
    <Icon width={11} height={9} variant="alert" className={iconClass} />
  );
  const infoIcon = (
    <Icon width={12} height={12} variant="info" className={iconClass} />
  );

  useEffect(() => {
    if (isLoading || !badgeData?.tagArray?.length) {
      setRows([[], []]);
      return;
    }
    // Clear badge refs before updating
    badgeRefs.current = {};
    setRows([[], []]);
    setIsLayoutReady(false);

    const updateLayout = () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const computedStyle = window.getComputedStyle(container);
      const paddingLeft = parseFloat(computedStyle.paddingLeft);
      const paddingRight = parseFloat(computedStyle.paddingRight);
      const availableWidth = container.clientWidth - paddingLeft - paddingRight;

      let firstRow: Tag[] = [];
      let secondRow: Tag[] = [];
      let currentWidth = 0;
      let row = 1;
      let visibleBadges = 0;
      const totalBadges = badgeData?.tagArray?.length;

      badgeData?.tagArray?.forEach((badge, index) => {
        if (visibleBadges >= badgeData.tagsShown) return;

        const badgeWidth =
          badgeRefs.current[badge.title]?.getBoundingClientRect().width || 0;

        if (currentWidth + badgeWidth > availableWidth) {
          if (row === 1) {
            row = 2;
            currentWidth = 0;
          } else {
            return;
          }
        }

        if (row === 1) {
          firstRow.push(badge);
        } else {
          secondRow.push(badge);
        }

        currentWidth += badgeWidth + GAP_SIZE;
        visibleBadges++;
      });

      const remainingBadges = totalBadges - visibleBadges;

      if (remainingBadges > 0) {
        const moreBadge: Tag = {
          isWarning: false,
          title: `+${remainingBadges}`,
        };

        if (secondRow.length > 0) {
          // Replace the last badge in secondRow with "+X" to prevent a 3rd row
          secondRow[secondRow.length - 1] = moreBadge;
        } else if (firstRow.length > 0) {
          // If secondRow is empty, replace the last badge in firstRow
          firstRow[firstRow.length - 1] = moreBadge;
        }
      }

      setRows([firstRow, secondRow]);
      setIsLayoutReady(true);
    };

    setTimeout(updateLayout, 50); // Small delay to ensure refs are set

    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, [badgeData, isLoading]);

  return (
    <div ref={containerRef} onClick={onClick} className={badgesContainerClass}>
      {/* Hidden badges for measuring width */}
      <div className="absolute invisible">
        {badgeData?.tagArray?.map((badge) => (
          <div
            key={badge.title}
            ref={(el) => (badgeRefs.current[badge.title] = el)}
            className="inline-block">
            <Badge
              Icon={badge.isWarning ? warningIcon : infoIcon}
              text={badge.title}
              textClassName="hidden-text"
              className="hidden-badge"
            />
          </div>
        ))}
      </div>

      {/* Render only after layout is calculated */}
      {isLayoutReady &&
        rows.map((row) => (
          <div className={rowContainerClass}>
            {row.map((badge) => (
              <Badge
                key={badge.title}
                Icon={badge.isWarning ? warningIcon : infoIcon}
                text={badge.title}
                textClassName={tagTextClass}
                className={tagClass}
              />
            ))}
          </div>
        ))}
    </div>
  );
};

export default BadgesContainer;
