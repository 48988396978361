import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  select24Format,
  selectUTC,
} from '../../../redux/reducers/settingsReducer';
import { formatNotificationTime } from '../../../utils/notifications/notificationsUtil';
import Badge from '../../Badge/Badge';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import { BADGE_CONFIG } from '../../../utils/components_config';
import {
  FlightStatus,
  NotificationType,
  UserNotification,
} from '../../../utils/generated/graphql';
import { setIsMenuOpen } from '../../../redux/reducers/headerReducer';
import { selectAllNotifications } from '../../../redux/reducers/newsfeedReducer';
import { setOpenOverlay } from '../../../redux/reducers/overlayReducer';
import PulseNotificationIcon from '../../PulseNotificationIcon/PulseNotificationIcon';
import useNetworkStatus from '../../../utils/hooks/useNetworkStatus';
import { useReadNotifications } from '../../../utils/hooks/useReadNotifications';
import FeatureFlagUtil from '../../../utils/FeatureFlagUtil';
import { NativeNotificationsUtil } from '../../../utils/notifications/NativeNotificationsUtil';

interface INotificationProps {
  time: string;
  subtitle: string;
  text: string;
  isNewNotification: boolean;
  isExpanded: boolean;
  isFlyinNotification: boolean;
}

interface INotificationAccordionProps {
  notificationsList: UserNotification[];
  isFlightSpecific: boolean;
  isOverlay: boolean;
  status: FlightStatus;
  isCheckedIn: boolean;
}

export const Notification = ({
  time,
  subtitle,
  text,
  isNewNotification,
  isExpanded,
  isFlyinNotification = false,
}: INotificationProps) => {
  return (
    <div
      className={classNames('flex mt-24 gap-0 justify-between flex-row', {
        'px-16': !isFlyinNotification,
      })}>
      <div className="flex">
        {isExpanded && (
          <div className="flex items-center h-[21px] mr-16">
            <span
              className={classNames('h-2 w-2 rounded-full', {
                'bg-secondary': isNewNotification && isExpanded,
              })}></span>
          </div>
        )}
        <div className="flex flex-col">
          <div className="max-w-[280px] whitespace-normal font-body-bold text-14 text-grey-80 dark:text-white">
            {subtitle}
          </div>
          <div className="flex flex-row">
            <pre className="max-w-[280px] whitespace-pre-line font-body-text text-14 text-grey-60">
              {text}
            </pre>
          </div>
        </div>
      </div>
      <div className="self-start font-body-text text-12 text-grey-40 whitespace-nowrap">
        {time}
      </div>
    </div>
  );
};

const getNotificationGroupIdentifier = (
  firstNotification: UserNotification
) => {
  const type = firstNotification?.notification?.type;
  if (type === NotificationType.SYSTEM) {
    return NotificationType.SYSTEM;
  }
  if (type === NotificationType.STATION) {
    return firstNotification?.notification?.details?.station;
  }
  if (type === NotificationType.ROLE) {
    return firstNotification?.notification?.title;
  }
  return firstNotification.notification?.details?.flightId ?? '';
};

const NotificationAccordion = ({
  notificationsList = [],
  isFlightSpecific,
  isOverlay = false,
  status,
  isCheckedIn = false,
}: INotificationAccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showBorder, setShowBorder] = useState(true);
  const use24Format = useSelector(select24Format);
  const useUTC = useSelector(selectUTC);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOnline = useNetworkStatus();
  const allNotifications = useSelector(selectAllNotifications);

  const areUnreadNotifications = notificationsList.some((n) => !n.read);
  const flightId = notificationsList?.[0]?.notification?.details?.flightId;
  const showLeftBorder =
    showBorder && areUnreadNotifications && !isFlightSpecific;
  const containerClassNames = classNames(
    'w-full pt-24 pb-32 bg-white dark:bg-grey-90 border-l-6',
    {
      'border-secondary': showLeftBorder,
      'border-white dark:border-grey-90': !showLeftBorder,
      'rounded-4': !isOverlay,
    }
  );

  const renderedNotifications =
    isExpanded || isFlightSpecific
      ? notificationsList
      : notificationsList.slice(0, 1);

  const readNotifications = useReadNotifications();

  const handleExpand = () => {
    if (isExpanded || isFlightSpecific) {
      const notificationsToBeRead = notificationsList?.filter(
        (notification) => !notification.read
      );

      if (isOnline && notificationsToBeRead?.length) {
        readNotifications(allNotifications, [
          getNotificationGroupIdentifier(notificationsToBeRead[0]) ?? '',
        ]);
      }
    } else {
      isOnline && setShowBorder(false);
    }
    setIsExpanded((prev) => !prev);
  };
  return (
    <div className={containerClassNames}>
      <div className="flex justify-between items-center leading-[19px] mb-24 px-16">
        <div
          className={classNames(
            'w-[131px] font-head-bold text-18 text-primary dark:text-white cursor-pointer flex gap-4',
            { 'pointer-events-none': !isOnline }
          )}
          onClick={() => {
            if (flightId && isOnline) {
              navigate(`/favourites/${flightId}/process`);
              dispatch(setIsMenuOpen(false));
              dispatch(setOpenOverlay(null));
            }
          }}>
          {NativeNotificationsUtil.formatNotificationsTitle(
            notificationsList?.[0]?.notification,
            useUTC
          )}
          {FeatureFlagUtil.showFeature(
            process?.env?.REACT_APP_TAC_CHECKIN_FEATURE_DISABLED ?? '',
            []
          ) &&
            isCheckedIn && <PulseNotificationIcon />}
        </div>
        <div className="flex gap-x-[16px]">
          {status && status !== FlightStatus.DEFAULT && (
            <>
              <div className=" laptopL:hidden desktop:hidden">
                <Badge
                  {...(status === FlightStatus.CANCELLED
                    ? BADGE_CONFIG.CANCELLED_NO_TEXT
                    : BADGE_CONFIG.DEPARTED_NO_TEXT)}
                />
              </div>
              <div className="mobile:hidden tablet:hidden tabletL:hidden">
                <Badge
                  {...(status === FlightStatus.CANCELLED
                    ? BADGE_CONFIG.CANCELLED
                    : BADGE_CONFIG.DEPARTED)}
                />
              </div>
            </>
          )}
          {
            <Badge
              text={notificationsList.filter((n) => !n.read).length}
              className={classNames('bg-secondary', {
                invisible: !areUnreadNotifications || isFlightSpecific,
              })}
              width={'w-44'}
              textClassName="text-primary"
            />
          }

          {!isFlightSpecific && (
            <Button
              Icon={
                <Icon
                  variant="arrowExpand"
                  className={classNames('dark:fill-white', {
                    'transform rotate-180': isExpanded || isFlightSpecific,
                  })}
                />
              }
              onClick={handleExpand}
            />
          )}
        </div>
      </div>
      {renderedNotifications.map((notification) => (
        <Notification
          key={notification.notification._id ?? 'notification-accordion'}
          time={formatNotificationTime(
            notification.notification.sentAt ??
              notification.notification.createdAt,
            use24Format
          )}
          subtitle={notification.notification.subtitle}
          text={NativeNotificationsUtil.formatNotificationsDescription(
            notification.notification,
            {
              useUTC,
              use24Format,
            }
          )}
          isNewNotification={!notification.read}
          isExpanded={isExpanded || isFlightSpecific}
          isFlyinNotification={false}
        />
      ))}
    </div>
  );
};

export default NotificationAccordion;
