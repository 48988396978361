import { useState, useEffect } from 'react';
import SearchFilterOverlay from './SearchFilterOverlay';
import { defaultSearchFilter } from './searchFilterMock';
import React from 'react';

interface ISearchFilterOverlayContainer {
  openFilterOverlay: (isOpen: boolean) => void;
  applyFilter: (isApplied: boolean) => void;
  isLoading: boolean;
}

function SearchFilterOverlayContainer({
  isLoading,
  openFilterOverlay,
  applyFilter,
}: ISearchFilterOverlayContainer) {
  // We will get config data here. Also we will get default filter from Mongo here
  // For the moment we use mock data...

  const [filters, setFilters] = useState(defaultSearchFilter);

  return (
    <SearchFilterOverlay
      filters={filters}
      isLoading={isLoading}
      openFilterOverlay={openFilterOverlay}
      applyFilter={applyFilter}
    />
  );
}

export default SearchFilterOverlayContainer;
