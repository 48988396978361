import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useCustomHeader } from '../../utils/hooks/useCustomHeader';
import MyProfile from '../../components/Settings/MyProfile/MyProfile';
import Grid from '../../components/Grid/Grid';
import TimeZone from '../../components/Settings/TimeZone/TimeZone';
import Title from '../../components/Title/Title';
import Dropdown from '../../components/Settings/Dropdown/Dropdown';
import Theming from '../../components/Settings/Theming/Theming';
import Button from '../../components/Button/Button';
import {
  AUTOMATIC,
  INTERFACE,
  USER_ROLES,
  LOCAL_TIME,
  LOGOUT,
  FLY_IN_NOTIFICATIONS,
  REGIONAL_SETTING,
  RESET_ALL,
  SAVE,
  SETTINGS,
  THEMING,
  TIME_ZONE,
  TIMEZONE_SETTINGS,
  UTC,
  ERRORS,
  NOTIFICATION_MSG,
  HOUR_FORMAT_24,
  HOUR_FORMAT_12,
  HOUR_24,
  DEFAULT_SETTING_MY_FLIGHTS,
  NOTIFICATIONS_FOR_STATIONS,
  NOTIFICATIONS_FOR_CHAT,
} from '../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTheming,
  selectUTC,
  selectFirstName,
  selectPhone,
  updateUserSettingsSuccess,
  setUserSettingsFailure,
  setUserSettingsStart,
  selectIsFormError,
  select24Format,
  selectStationMuted,
  selectFavoriteFlightsMuted,
  selectStation,
  selectStationsList,
  selectChatMuted,
  selectRoles,
  selectNativeNotificationsMuted,
  selectPhoneReminder,
} from '../../redux/reducers/settingsReducer';
import {
  logout,
  selectUserId,
  selectAuthReducer,
  selectIsLogOutPending,
  setLogoutPending,
} from '../../redux/reducers/authReducer';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { useNotifications } from '../../utils/hooks/useNotifications';
import Notifications from '../../components/Settings/Notifications/Notifications';
import StationInput from '../../components/Settings/StationInput/StationInput';
import { useUpdateUserSettings } from '../../utils/hooks/useUpdateUserSettings';
import {
  Permission,
  UpdateSettingsLocations,
  UserSettingsInput,
} from '../../utils/generated/graphql';
import { AppDispatch } from '../../redux/store';
import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import AppVersion from '../../components/AppVersion/AppVersion';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import useCheckPermissions from '../../utils/hooks/useCheckPermissions';
import { subscribeToStations } from '../../utils/notifications/subscribeToStations';
import FeatureFlagUtil from '../../utils/FeatureFlagUtil';

const Settings = () => {
  useCustomHeader({
    headerTypeMobile: 'simple',
    hasBackArrow: true,
    title: SETTINGS,
  });
  const {
    onUpdateUserSettings,
    loading: isLoading,
    responseErrors,
  } = useUpdateUserSettings();

  const { addError, addSuccess } = useNotifications(
    responseErrors?.[0],
    responseErrors?.[0]?.message
  );

  const dispatch = useDispatch<AppDispatch>();
  const tokenSettings = useSelector(selectAuthReducer);
  const station = useSelector(selectStation);
  const stationsList: string[] = useSelector(selectStationsList);
  const chatMuted = useSelector(selectChatMuted);
  const theming = useSelector(selectTheming);
  const firstName = useSelector(selectFirstName);
  const phoneNumber = useSelector(selectPhone);
  const useUTC = useSelector(selectUTC);
  const use24 = useSelector(select24Format);
  const userId = useSelector(selectUserId);
  const isFormError = useSelector(selectIsFormError);
  const stationMuted = useSelector(selectStationMuted);
  const isPhoneReminder = useSelector(selectPhoneReminder);
  const favoriteFlightsMuted = useSelector(selectFavoriteFlightsMuted);
  const permissionStatus = window?.Notification && Notification.permission;
  const nativeNotificationsMuted = useSelector(selectNativeNotificationsMuted);
  const roles = useSelector(selectRoles);
  const isLogOutPending = useSelector(selectIsLogOutPending);

  const hasChatReadPermission = useCheckPermissions([Permission.CHAT_READ]);

  const [userProfileData, setUserProfileData] = useState({
    firstName: firstName === '' ? tokenSettings.tokenFirstName : firstName,
    phoneNumber: phoneNumber ?? '',
  });

  const [stations, setStations] = useState(
    stationsList?.length !== 0 ? stationsList : station !== '' ? [station] : []
  );

  const [_, setHasNotificationPermission] = useState(
    !!window?.Notification && Notification.permission === 'granted'
  );

  const [settingsData, setSettingsData] = useState({
    theme: theming,
    useUTC,
    use24,
    stationMuted,
    favoriteFlightsMuted,
    nativeNotificationsMuted,
    chatMuted,
  });
  const timeZone = settingsData.useUTC ? UTC : LOCAL_TIME;
  const timeFormat = settingsData.use24 ? HOUR_FORMAT_24 : HOUR_FORMAT_12;
  const selectedTimeFormat = timeFormat.options[0].selected
    ? timeFormat.options[0].value
    : timeFormat.options[1].value;
  const formData = { ...settingsData, ...userProfileData };

  const handleInputChange = (e, isInUserProfile = false) => {
    const { name, value } = e.target;

    if (isInUserProfile) {
      setUserProfileData({ ...userProfileData, [name]: value });
      return;
    }

    if (name === 'timeZone') {
      setSettingsData({
        ...settingsData,
        useUTC: value === UTC,
      });
      return;
    }
    if (
      name === 'favoriteFlightsMuted' ||
      name === 'stationMuted' ||
      name === 'nativeNotificationsMuted' ||
      name === 'chatMuted'
    ) {
      setSettingsData({
        ...settingsData,
        [name]: !settingsData[name],
      });

      return;
    }
    setSettingsData({
      ...settingsData,
      [name]: value,
    });
  };

  const handleNativeNotificationChange = async (e) => {
    const { value } = e.target;
    // currently there's no way to programatically revoke permissions so we're interested only in the following case
    if (value === 'false') {
      const permission = await Notification.requestPermission();

      if (permission === 'granted') {
        setHasNotificationPermission(true);
      }
    }
    handleInputChange(e);
  };

  const handleDropdownChange = (option) => {
    setSettingsData({
      ...settingsData,
      use24: option === HOUR_24 ? true : false,
    });
  };

  const containerClassNames =
    'bg-white rounded-8 px-24 pt-24 pb-24 dark:bg-grey-90';

  const buttonClassName =
    'font-head-bold w-[158px] py-13 flex justify-center rounded-4';

  const resetBtnClass = classNames(
    buttonClassName,
    'ml-auto mobile:mr-auto mobile:ml-0'
  );

  const btnClass = classNames(
    buttonClassName,
    'flex items-center h-44 ml-[15px] bg-primary dark:bg-grey-12 mobile:ml-[16px]'
  );

  const hasStationNotificationAccess = useCheckPermissions([
    Permission.RECEIVE_STATION_NOTIFICATIONS,
  ]);

  const saveUserSettings = (data: UserSettingsInput) => async (dispatch) => {
    dispatch(setUserSettingsStart());
    try {
      const result = await onUpdateUserSettings(
        data,
        UpdateSettingsLocations.SETTINGS
      );
      dispatch(addSuccess(NOTIFICATION_MSG.SAVED));
      dispatch(updateUserSettingsSuccess(result));

      const addedStations =
        result?.stationsList.filter(
          (station) => !stationsList.includes(station)
        ) ?? [];

      const removedStations =
        stationsList.filter(
          (station) => !result?.stationsList.includes(station)
        ) ?? [];

      if (
        FeatureFlagUtil.showFeature(
          process?.env?.REACT_APP_TAC_ADMIN_PAGE_FEATURE_DISABLED ?? '',
          roles
        ) &&
        hasStationNotificationAccess
      ) {
        //subscribe to new stations
        subscribeToStations(userId, addedStations, useUTC, use24, dispatch);

        //unsubscribe from deleted stations
        UtilFactory.newMessageHandler().unsubscribe(
          removedStations.map((station) => `${MQTT_TOPIC.STATION}${station}`),
          true
        );
      }
    } catch (error) {
      dispatch(addError(ERRORS.COULD_NOT_SAVE));
      dispatch(setUserSettingsFailure(ERRORS.COULD_NOT_SAVE));
    }
  };

  const handleSaveButton = () => {
    if (isFormError) {
      return;
    }

    const isPhoneReminderChanged = (
      reduxState: string,
      localState: string,
      phoneReminder: boolean
    ) => {
      if (reduxState === localState) {
        return phoneReminder;
      } else if (reduxState !== localState) {
        if (localState === '') {
          return true;
        } else {
          return false;
        }
      } else {
        return phoneReminder;
      }
    };

    const data = {
      ...formData,
      userName: userId,
      name: tokenSettings.tokenName,
      email: tokenSettings.tokenEmail,
      chatMuted: formData.chatMuted ?? false,
      stationsList: stations,
      station: stations.length !== 0 ? stations[0] : '',
      isPhoneReminderActive: isPhoneReminderChanged(
        phoneNumber,
        formData.phoneNumber,
        isPhoneReminder
      ),
    };

    dispatch(saveUserSettings(data));
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  const isOffline = !useNetworkStatus();

  useEffect(() => {
    dispatch(setLogoutPending(false));
  }, []);

  const renderButtons = () => {
    return (
      <>
        <Title
          title={SETTINGS}
          titleColorClass={'text-grey-60 text-18'}
          className="mobile:hidden"
        />
        <Button
          text={RESET_ALL}
          textClassName={
            'text-primary text-14 dark:text-grey-12 max-w-[102px] pl-[8px]'
          }
          className={classNames(resetBtnClass, { 'opacity-50': isOffline })}
          onClick={() =>
            setSettingsData({
              theme: AUTOMATIC,
              useUTC: TIMEZONE_SETTINGS.SET_UTC,
              use24: TIMEZONE_SETTINGS.SET_24,
              stationMuted: false,
              favoriteFlightsMuted: false,
              nativeNotificationsMuted: false,
              chatMuted: false,
            })
          }
          disabled={isOffline}
        />

        <Button
          text={isLoading ? <LoadingSpinner width={24} height={24} /> : SAVE}
          textClassName={
            'text-white text-14 dark:text-grey-90 max-w-[102px] pl-[8px]'
          }
          className={classNames(btnClass, { 'opacity-50': isOffline })}
          onClick={handleSaveButton}
          disabled={isLoading || isOffline}
        />

        <Button
          text={
            isLogOutPending ? <LoadingSpinner width={24} height={24} /> : LOGOUT
          }
          textClassName={
            'text-white text-14 dark:text-grey-90 max-w-[102px] pl-[8px]'
          }
          className={classNames(btnClass, { 'opacity-50': isOffline })}
          onClick={() => handleLogout()}
          disabled={isOffline}
        />
      </>
    );
  };

  return (
    <>
      <div className="flex sticky top-0 items-center z-10 bg-grey-6 dark:bg-black p-24 mobile:py-12 mobile:top-56">
        {renderButtons()}
      </div>
      <div className="px-24 pb-24 mobile:pt-[70px]">
        <div className="pb-32 mobile:pt-0">
          <MyProfile
            profileData={userProfileData}
            handleChange={(e) => handleInputChange(e, true)}
          />
        </div>
        <Grid col={[3, 16, 0]} mobile={[1, 0, 24]}>
          <div className={containerClassNames}>
            <Title
              title={REGIONAL_SETTING}
              titleColorClass={'text-primary text-14 font-head-light'}
              className="pb-32"
            />
            <StationInput
              stationsArray={stations}
              onStationsChange={setStations}
            />
            <Title
              title={TIME_ZONE}
              titleColorClass={'text-grey-40'}
              className="pt-24 pb-8"
            />
            <TimeZone
              timeZone={timeZone}
              handleChange={(e) => handleInputChange(e)}
            />

            {false && (
              <Dropdown
                {...timeFormat}
                handleChange={handleDropdownChange}
                disabled={isOffline}
                disabledOption={selectedTimeFormat}
              />
            )}
          </div>
          <div className={containerClassNames}>
            <Title
              title={FLY_IN_NOTIFICATIONS}
              titleColorClass={'text-primary text-14 font-head-light'}
              className="pb-32"
            />
            <Title
              title={DEFAULT_SETTING_MY_FLIGHTS}
              titleColorClass={'text-grey-40'}
              className="pb-8"
            />
            <Notifications
              notificationState={
                settingsData.favoriteFlightsMuted ? 'off' : 'on'
              }
              notificationType="favoriteFlightsMuted"
              handleChange={(e) => handleInputChange(e)}
              disabled={
                !FeatureFlagUtil.showFeature(
                  process?.env?.REACT_APP_TAC_NOTIFICATIONS_FEATURE_DISABLED ??
                    '',
                  roles
                ) || isOffline
              }
            />
            <Title
              title={NOTIFICATIONS_FOR_STATIONS}
              titleColorClass={'text-grey-40'}
              className="pb-8 pt-24"
            />
            <Notifications
              notificationState={settingsData.stationMuted ? 'off' : 'on'}
              notificationType="stationMuted"
              handleChange={(e) => handleInputChange(e)}
              disabled={
                !FeatureFlagUtil.showFeature(
                  process?.env?.REACT_APP_TAC_NOTIFICATIONS_FEATURE_DISABLED ??
                    '',
                  roles
                ) || isOffline
              }
            />
            <Title
              title={NOTIFICATIONS_FOR_CHAT}
              titleColorClass={'text-grey-40'}
              className="pb-8 pt-24"
            />
            <Notifications
              notificationState={settingsData.chatMuted ? 'off' : 'on'}
              notificationType="chatMuted"
              handleChange={(e) => handleInputChange(e)}
              disabled={
                !FeatureFlagUtil.showFeature(
                  process?.env?.REACT_APP_TAC_CHAT_FEATURE_DISABLED ?? '',
                  roles
                ) ||
                isOffline ||
                !hasChatReadPermission
              }
            />
            <Title
              title={'Native browser notifications'}
              titleColorClass={'text-grey-40'}
              className="pb-8 pt-24"
            />

            <Notifications
              notificationState={
                permissionStatus === 'granted' &&
                !settingsData.nativeNotificationsMuted
                  ? 'on'
                  : 'off'
              }
              notificationType="nativeNotificationsMuted"
              handleChange={(e) => handleNativeNotificationChange(e)}
              disabled={
                !FeatureFlagUtil.showFeature(
                  process?.env?.REACT_APP_TAC_NOTIFICATIONS_FEATURE_DISABLED ??
                    '',
                  roles
                ) ||
                !window?.Notification ||
                isOffline
              }
            />
          </div>
          <div className={containerClassNames}>
            <Title
              title={INTERFACE}
              titleColorClass={'text-primary text-14 font-head-light'}
              className="pb-32"
            />
            <Title
              title={THEMING}
              titleColorClass={'text-grey-40'}
              className="pb-8"
            />
            <Theming
              theme={settingsData.theme}
              handleChange={(e) => handleInputChange(e)}
              disabled={isOffline}
            />
          </div>
        </Grid>
        <div className={containerClassNames + ' mt-[29px]'}>
          <Title
            title={USER_ROLES}
            titleColorClass={'text-primary text-14 font-head-light'}
            className="pb-32"
          />
          <div>
            {roles &&
              roles.map((role) => (
                <div
                  key={role._id}
                  className="bg-grey-25 rounded-8 p-4 font-body-bold text-primary dark:text-black m-3 inline-block">
                  {role?.technicalRole ?? ''}
                </div>
              ))}
          </div>
        </div>
        {/* <button
      className="border-black border-1"
      onClick={() => {
        const newRoute = `/dummyPush`;
        navigate(newRoute);
      }}>
      Go to dummy page push
    </button> */}
        <AppVersion isHidden={false} />
      </div>
    </>
  );
};

export default Settings;
