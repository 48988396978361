import { useDispatch } from 'react-redux';
import { useMarkNotificationAsRead } from './useMarkNotificaitonAsRead';
import {
  IUserNotification,
  readFlightNotification,
} from '../../redux/reducers/newsfeedReducer';

export const useReadNotifications = () => {
  const { markAsRead } = useMarkNotificationAsRead();
  const dispatch = useDispatch();
  let allIds: string[] = [];

  return (notifications: IUserNotification[], topics: string[] | undefined) => {
    topics?.forEach((topic) => {
      const ids = notifications?.[topic ?? '']
        ?.filter((f) => !f.read)
        .map((f) => f.notification._id);
      try {
        if (topic && ids) {
          dispatch(readFlightNotification(topic));
          allIds = allIds.concat(ids);
        }
      } catch (err) {}
    });
    markAsRead(allIds);
  };
};
