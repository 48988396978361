import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAccessToken, getIdToken } from '../../../utils/helpers';
import { logout } from '../../reducers/authReducer';
import { createUniqueId } from '../../../utils/createUniqueId';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_TAC_BE_URL,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${getAccessToken()}`);
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set('ID-Token', getIdToken());
    headers.set('ID-Request', createUniqueId());
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.originalStatus === 401) {
    console.error('Invalid token!');
    api.dispatch(logout());
  }
  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  keepUnusedDataFor: 30,
  endpoints: (_builder) => ({}),
  tagTypes: ['Favourite', 'Process', 'Search'],
});

export const hardRefreshData = () => baseApi.util.invalidateTags(['Process']);
export const invalidateSearch = () => baseApi.util.invalidateTags(['Search']);

export const { middleware } = baseApi;
