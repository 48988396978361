import React, { FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_SUBJECT,
  NOTIFICATION_MODAL_BODY,
} from '../../../utils/constants';
import {
  Notification,
  NotificationType,
  RoleGroup,
} from '../../../utils/generated/graphql';
import Dropdown from '../../Settings/Dropdown/Dropdown';
import Datetime from 'react-datetime';
import Title from '../../Title/Title';
import {
  select24Format,
  selectStationsList,
} from '../../../redux/reducers/settingsReducer';
import 'react-datetime/css/react-datetime.css';
import './NotificationForm.scss';
import { NOTIFICATION_FORM_ID } from '../AdminNotificationCenter';
import Input from '../../Input/Input';
import TextArea from '../../TextArea/TextArea';
import { useGetNotificationMeta } from '../../../utils/hooks/useGetNotificationMeta';
import MultiselectDropdown from '../../MultiselectDropdown/MultiselectDropdown';
import { isNotificationInputValid } from '../../../utils/helpers';

const titleClass = 'flex items-center mobile:items-start mobile:flex-col';
const inputContainerClass =
  'relative bg-white w-full border-1 border-primary rounded-4 py-2 pl-14 pr-[7px] font-body-bold text-primary text-14 appearance-none focus:outline-none mt-8 dark:bg-grey-90 dark:border-grey-12 dark:text-grey-12';
const inputClass =
  'w-full appearance-none focus:outline-none pr-8 mb-8 dark:bg-grey-90';
const titleColorClass = 'w-[70px] text-grey-40';

const IS_CATEGORY_VISIBLE = false;

const FIELD_NAMES = {
  TYPE: 'Type',
  CATEGORY: 'Category',
  STATION: 'Station',
  SUBJECT: 'Subject',
  MESSAGE: 'Message',
  START: 'Start Date',
  END: 'End Date',
  TOPIC: 'Topic',
  TARGET_ROLES: 'Target Roles',
};

const DATE_FORMAT = 'DD MMM YYYY';

export interface ISubmitFields {
  e: FormEvent<HTMLFormElement>;
  startDate: string;
  endDate: string;
  stations?: string[] | null;
  targetRoles?: RoleGroup[] | null;
  senderRole?: RoleGroup | null;
  topic?: string | null;
  type?: NotificationType | null;
  description?: string;
  subtitle?: string;
}
interface INotificationForm {
  notification?: Notification;
  handleSubmit?: (elem: ISubmitFields) => void;
  setCanSubmit?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ITopic {
  topicMessage: string;
  topicRoleGroup: RoleGroup;
}

interface ITargetRole {
  targetRoleName: string;
  targetRoleGroup: RoleGroup;
}

const ROLE_SPECIFIC = {
  type: NotificationType.ROLE,
  label: 'ROLE SPECIFIC',
};

export const NotificationForm = ({
  notification = undefined,
  handleSubmit,
  setCanSubmit,
}: INotificationForm) => {
  const isEditMode = !!notification;
  const { data: metaData, loading } = useGetNotificationMeta(!!notification);
  const [type, setType] = useState<NotificationType | null>(null);
  const [topic, setTopic] = useState<ITopic | null>(null);
  const [targetRoles, setTargetRole] = useState<ITargetRole[] | null>(null);
  const [stations, setStations] = useState<string[]>(
    notification ? [notification.details.station ?? ''] : []
  );
  const [startDateValue, setStartDateValue] = useState(
    notification?.details?.schedule?.sendDate ?? ''
  );
  const [endDateValue, setEndDateValue] = useState(
    notification?.details?.schedule?.deleteDate ?? ''
  );
  const [subtitle, setSubtitle] = useState<string>(
    notification?.subtitle ?? ''
  );
  const [description, setDescription] = useState<string>(
    notification?.description ?? ''
  );
  const is24Format = useSelector(select24Format);

  const TIME_FORMAT = is24Format ? 'HH:mm' : 'hh:mm A';
  const DATE_TIME_FORMAT = 'DD MMM '.concat(TIME_FORMAT);

  useEffect(() => {
    notification
      ? setType(notification?.type)
      : setType(metaData?.types?.[0].type ?? null);
  }, [notification]);

  const typeOptions =
    metaData?.types?.map((notificationType) => {
      const type =
        notificationType?.type === ROLE_SPECIFIC.type
          ? ROLE_SPECIFIC.label
          : notificationType?.type.toString() ?? '';
      return {
        value: type,
        label: type,
      };
    }) ?? [];

  const defaultTypeOptions = notification
    ? [
        {
          value: notification.type.toString(),
          label: notification.type.toString(),
        },
      ]
    : typeOptions;

  const categoryOptions = metaData?.types
    ?.find((notificationType) => notificationType?.type === type)
    ?.categories?.map((category) => ({ value: category, selected: false }));

  const defaultCategoryOptions = notification
    ? categoryOptions?.map((option) => {
        return { ...option, selected: option.value === notification.category };
      })
    : categoryOptions;

  const defaultTopicOptions =
    metaData?.broadcastRoles?.map((broadcastEntry) => ({
      label: `${broadcastEntry?.roleName} message`,
      value: broadcastEntry?.roleGroup.toString() ?? '',
      roleGroup: broadcastEntry?.roleGroup,
    })) ?? [];

  const targetRolesOptions =
    metaData?.broadcastRoles
      ?.find((entry) => topic?.topicRoleGroup === entry?.roleGroup)
      ?.targetRoles.map((role) => ({
        label: role.roleName,
        value: role.roleGroup,
      })) ?? [];

  const formattedStartDate = startDateValue
    ? moment(startDateValue).format(DATE_TIME_FORMAT)
    : '';
  const formattedEndDate = endDateValue
    ? moment(endDateValue).format(DATE_TIME_FORMAT)
    : '';
  const stationsList = useSelector(selectStationsList);
  const stationOptions = stationsList?.map((station) => ({
    value: station,
    label: station,
    selected: false,
  }));

  const selectedStationIndex = notification
    ? stationOptions.findIndex(
        (station) => station.value === notification.details.station
      )
    : undefined;

  const defaultStationOptions = notification
    ? stationOptions
    : type === NotificationType.STATION || type === ROLE_SPECIFIC.label
    ? stationOptions
    : [];

  useEffect(() => {
    const currentType =
      type === ROLE_SPECIFIC.label ? ROLE_SPECIFIC.type : type;

    const stationLengthValidity =
      currentType === NotificationType.STATION ||
      currentType === NotificationType.ROLE
        ? !!stations?.length
        : true;

    const targetRolesValidity =
      currentType === NotificationType.ROLE ? !!targetRoles?.length : true;

    const shouldCreate =
      stationLengthValidity &&
      targetRolesValidity &&
      isNotificationInputValid(description) &&
      isNotificationInputValid(subtitle);

    setCanSubmit && setCanSubmit(shouldCreate);
  }, [subtitle, description, type, stations, targetRoles]);

  return (
    <form
      id={NOTIFICATION_FORM_ID}
      onSubmit={(e) =>
        handleSubmit &&
        handleSubmit({
          e,
          startDate: startDateValue,
          endDate: endDateValue,
          stations,
          targetRoles: targetRoles?.length
            ? targetRoles.map((role) => role.targetRoleGroup)
            : null,
          senderRole: topic?.topicRoleGroup,
          topic: topic?.topicMessage,
          type: type === ROLE_SPECIFIC.label ? ROLE_SPECIFIC.type : type,
          description,
          subtitle,
        })
      }>
      <div className="max-h-screen space-y-[10px] overflow-scroll">
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
          <h1 className="text-primary">
            {NOTIFICATION_MODAL_BODY.NOTIFICATION_CONFIGURATION}
          </h1>
          <div className="pt-8">
            <MultiselectDropdown
              inputClassName="w-full"
              isLoading={loading}
              defaultSelectedIndex={0}
              disabled={!!notification || loading}
              selectionLimit={1}
              className="p-0 w-full min-w-full flex flex-row items-center "
              containerClassName="w-full !mt-0 "
              titleClassName="basis-1/6 text-grey-40"
              options={defaultTypeOptions}
              handleChange={(e) => {
                if (!isEditMode) {
                  setType(e);
                  setTopic(null);
                  setStations([]);
                  setTargetRole([]);
                }
              }}
              title={FIELD_NAMES.TYPE + '*'}
              disableSearch
              hasSelectAll={false}
            />

            <MultiselectDropdown
              inputClassName="w-full"
              isLoading={loading}
              disabled={
                !!notification || loading || type !== ROLE_SPECIFIC.label
              }
              selectionLimit={1}
              className="p-0 w-full min-w-full flex flex-row items-center mt-[10px]"
              titleClassName="basis-1/6 text-grey-40"
              containerClassName="w-full !mt-0 "
              options={defaultTopicOptions}
              handleChange={(e) => {
                if (!isEditMode) {
                  setTopic({
                    topicMessage: e,
                    topicRoleGroup:
                      defaultTopicOptions.find((topic) => topic.label === e)
                        ?.roleGroup ?? RoleGroup.UNKNOWN,
                  });
                  setStations([]);
                  setTargetRole([]);
                }
              }}
              clearInput={!topic}
              title={`${FIELD_NAMES.TOPIC}${
                type === ROLE_SPECIFIC.label ? `*` : ``
              }`}
              disableSearch
              hasSelectAll={false}
            />
            {IS_CATEGORY_VISIBLE ? (
              <Dropdown
                disabled={defaultCategoryOptions?.length === 0}
                disabledOption={notification?.category ?? ''}
                inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
                className="p-0 w-[400px] flex"
                options={defaultCategoryOptions ?? []}
                title={FIELD_NAMES.CATEGORY}
              />
            ) : (
              <MultiselectDropdown
                inputClassName="w-full"
                isLoading={loading}
                disabled={
                  isEditMode
                    ? true
                    : defaultStationOptions?.length === 0 ||
                      type === NotificationType.SYSTEM ||
                      (type === ROLE_SPECIFIC.label && !topic)
                }
                selectionLimit={
                  type === NotificationType.STATION ? 1 : undefined
                }
                className="p-0 w-full min-w-full flex flex-row items-center mt-[10px]"
                titleClassName="basis-1/6 text-grey-40"
                containerClassName="w-full !mt-0 "
                options={defaultStationOptions}
                title={FIELD_NAMES.STATION + '*'}
                handleChange={!isEditMode ? setStations : undefined}
                defaultSelectedIndex={
                  selectedStationIndex !== -1 ? selectedStationIndex : undefined
                }
                disableSearch
                hasSelectAll={false}
                clearInput={!stations?.length}
              />
            )}

            <MultiselectDropdown
              inputClassName="w-full"
              handleChange={(e) => {
                const targetArray = e?.map((targetName) => {
                  return {
                    targetRoleName: targetName.label,
                    targetRoleGroup:
                      targetRolesOptions.find(
                        (target) => target.label === targetName
                      )?.value ?? RoleGroup.UNKNOWN,
                  };
                });
                setTargetRole(targetArray);
              }}
              disabled={
                targetRolesOptions?.length === 0 ||
                type !== ROLE_SPECIFIC.label ||
                !topic
              }
              className="p-0 w-full min-w-full flex flex-row items-center mt-[10px]"
              titleClassName="basis-1/6 text-grey-40"
              containerClassName="w-full !mt-0 "
              options={targetRolesOptions}
              title={`${FIELD_NAMES.TARGET_ROLES}${
                type === ROLE_SPECIFIC.label ? `*` : ``
              }`}
              disableSearch
              hasSelectAll={false}
              clearInput={!targetRoles?.length || !targetRoles}
            />
          </div>
        </div>
        {IS_CATEGORY_VISIBLE && (
          <div className="bg-white border mobile:border-none border-1 dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
            <h1 className="text-primary">
              {NOTIFICATION_MODAL_BODY.TARGET_AUDIENCE}
            </h1>
            <Dropdown
              inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
              className="p-0 w-[135px]"
              options={defaultStationOptions}
              title={FIELD_NAMES.STATION}
            />
          </div>
        )}
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90 mobile:space-y-[8px]">
          <h1 className="text-primary">{NOTIFICATION_MODAL_BODY.MESSAGE}</h1>
          <Title
            titleColorClass={titleColorClass}
            textClassName="w-full"
            title={FIELD_NAMES.SUBJECT}
            className={titleClass}>
            <Input
              onChange={setSubtitle}
              containerClassName={inputContainerClass}
              inputClassName={inputClass}
              maxLength={MAX_LENGTH_SUBJECT}
              defaultValue={notification?.subtitle}
            />
          </Title>
          <Title
            titleColorClass={titleColorClass}
            textClassName="w-full"
            title={FIELD_NAMES.MESSAGE}
            className={titleClass}>
            <TextArea
              onChange={(e) => setDescription(e?.target?.value?.toString())}
              containerClassName={inputContainerClass}
              areaClassName={inputClass}
              rows={5}
              maxLength={MAX_LENGTH_DESCRIPTION}
              defaultValue={notification?.description}
            />
          </Title>
        </div>
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
          <h1 className="text-primary">{NOTIFICATION_MODAL_BODY.SCHEDULE}</h1>
          <div className="flex justify-between mobile:flex-wrap mobile:gap-y-8 mt-8 gap-x-8">
            <Title
              titleColorClass={titleColorClass}
              title={FIELD_NAMES.START}
              className="mobile:min-w-[140px] flex-1">
              <Datetime
                renderInput={(props) => (
                  <input {...props} readOnly value={formattedStartDate} />
                )}
                onChange={(value) => setStartDateValue(value.toString())}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                initialValue={formattedStartDate}
                className="rdtPickerOpenUpwardsRight dark:text-black"
                inputProps={{
                  className:
                    'border-1 border-black dark:border-grey-12 rounded-4 p-8 mt-8 font-body-bold text-primary text-14 dark:bg-grey-90 dark:text-grey-12 w-full',
                }}
              />
            </Title>
            <Title
              titleColorClass={titleColorClass}
              title={FIELD_NAMES.END}
              className="mobile:min-w-[140px] flex-1">
              <Datetime
                renderInput={(props) => (
                  <input {...props} readOnly value={formattedEndDate} />
                )}
                onChange={(value) => setEndDateValue(value.toString())}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                initialValue={formattedEndDate}
                className="rdtPickerOpenUpwardsLeft dark:text-black"
                inputProps={{
                  className:
                    'border-1 border-black dark:border-grey-12 font-body-bold text-primary text-14 rounded-4 p-8 mt-8 dark:bg-grey-90 dark:text-grey-12 w-full',
                }}
              />
            </Title>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NotificationForm;
