import { ISearchFilterGroup } from './SearchFilterOverlay';

export const defaultSearchFilter: ISearchFilterGroup[] = [
  {
    category: 'carriers',
    label: 'Lufthansa Group',
    cols: 'one',
    values: [
      { id: 'LH', label: 'LH', isDefault: true },
      { id: 'LX', label: 'LX', isDefault: true },
      { id: 'OS', label: 'OS', isDefault: true },
      { id: 'SN', label: 'SN', isDefault: true },
      { id: '4Y', label: '4Y', isDefault: true },
      { id: 'VL', label: 'VL', isDefault: true },
      { id: 'EN', label: 'EN', isDefault: true },
      { id: 'AZ', label: 'AZ', isDefault: true },
    ],
  },
  {
    category: 'cal',
    label: 'Customer Airlines',
    cols: 'one',
    values: [
      { id: 'A3', label: 'A3', isDefault: false },
      { id: 'AC', label: 'AC', isDefault: false },
      { id: 'BT', label: 'BT', isDefault: false },
      { id: 'BR', label: 'BR', isDefault: false },
      { id: 'CA', label: 'CA', isDefault: false },
      { id: 'KM', label: 'KM', isDefault: false },
      { id: 'LG', label: 'LG', isDefault: false },
      { id: 'LO', label: 'LO', isDefault: false },
      { id: 'MS', label: 'MS', isDefault: false },
      { id: 'NH', label: 'NH', isDefault: false },
      { id: 'OU', label: 'OU', isDefault: false },
      { id: 'SQ', label: 'SQ', isDefault: false },
      { id: 'TG', label: 'TG', isDefault: false },
      { id: 'TP', label: 'TP', isDefault: false },
      { id: 'UA', label: 'UA', isDefault: false },
    ],
  },
  {
    category: 'serviceType',
    label: 'Service Types',
    cols: 'two',
    values: [
      { id: 'passenger', label: 'Passenger', isDefault: true },
      { id: 'bus', label: 'Bus/Train', isDefault: false },
      { id: 'other', label: 'Other', isDefault: false },
    ],
  },
  {
    category: 'flightStatus',
    label: 'Flight Status',
    cols: 'two',
    values: [
      { id: 'scheduled', label: 'Scheduled', isDefault: true },
      { id: 'delayed', label: 'Delayed', isDefault: true },
      { id: 'departed', label: 'Departed', isDefault: true },
      { id: 'cancelled', label: 'Cancelled', isDefault: true },
    ],
  },
];
