import { Dispatch } from '@reduxjs/toolkit';
import UtilFactory from '../../factory/UtilFactory';
import { MQTT_TOPIC } from '../../models/MqttTopics';
import {
  deleteCheckinUser,
  setCheckedoutHighlander,
} from '../../redux/reducers/checkinReducer';
import { setIsCheckIn } from '../../redux/reducers/headerReducer';
import { setCountdownToZero } from '../../redux/reducers/reloadReducer';
import { calculateFlightNumber } from '../calculateFlightNumber';

const messageHandler = UtilFactory.newMessageHandler();

interface ISubscribeTOHighlander {
  flightId: string;
  userId: string;
  dispatch: Dispatch;
  isUTC: boolean;
}

export const subscribeToHighlander = (
  subscriptions: ISubscribeTOHighlander[]
) => {
  messageHandler.subscribe(
    subscriptions.map((subscription) => ({
      topic: `${MQTT_TOPIC.HIGHLANDER}${subscription.flightId}/${subscription.userId}`,
      onMessage: async (topic: string, message: string) => {
        const checkedoutUserData = JSON.parse(message);
        subscription.dispatch(setCountdownToZero());
        subscription.dispatch(
          setCheckedoutHighlander({
            isCheckedOut: true,
            checkedoutFlight: checkedoutUserData.flightId,
            checkedoutRole: checkedoutUserData.role,
            checkedoutFlightNumber: calculateFlightNumber(
              subscription.isUTC,
              checkedoutUserData.flightNumber
            ),
          })
        );
        subscription.dispatch(deleteCheckinUser(checkedoutUserData.flightId));
        subscription.dispatch(setIsCheckIn(false));
        unsubscribeToHighlander(
          [checkedoutUserData.flightId],
          subscription.userId
        );
      },
    }))
  );
};

export const unsubscribeToHighlander = (
  flightIds: string[],
  userId: string
) => {
  const topics = flightIds.map(
    (flightId) => `${MQTT_TOPIC.HIGHLANDER}${flightId}/${userId}`
  );
  messageHandler.unsubscribe(topics, true);
};
