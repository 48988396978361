import React, { useState } from 'react';
import './Checkbox.scss';
import classNames from 'classnames';

interface ICheckboxProps {
  className?: string;
  inputClassName?: string;
  label: string;
  checked?: boolean;
  width?: number;
  height?: number;
}

const Checkbox = ({
  label,
  checked,
  className,
  inputClassName,
  ...props
}: ICheckboxProps) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  return (
    <label
      className={classNames(
        'flight-search-checkbox',
        'flex items-center space-x-1 cursor-pointer',
        className
      )}>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className={classNames(
          `w-[20px] h-[20px] cursor-pointer`,
          inputClassName
        )}
        {...props}
      />
      <span className="font-bold text-grey-40">{label}</span>
    </label>
  );
};

export default Checkbox;
