import React, { useState } from 'react';
import MenuHeader from '../Navigation/Header/MenuHeader/MenuHeader';
import classNames from 'classnames';
import Button from '../Button/Button';
import { useDispatch } from 'react-redux';
import { setIsMenuOpen } from '../../redux/reducers/headerReducer';
import { setIsBriefingPackageOpen } from '../../redux/reducers/briefingPackageReducer';
import {
  BRIEFING_PACKAGE,
  DOWNLOAD_BRIEFING_PACKAGE_ERROR,
  FILE_TYPES,
  NO_REGISTRATION_FILE_NAME,
} from '../../utils/constants';
import Title from '../Title/Title';
import Icon from '../Icon/Icon';
import { useGetBriefingPackageLazy } from '../../utils/hooks/useGetBriefingPackage';
import {
  createBriefingPackageFileName,
  downloadFileObject,
} from '../../utils/downloadFile';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { addError } from '../../redux/reducers/notificationReducer';

interface IBriefingPackage {
  handleCloseBriefingPackage: () => void;
  flightId: string;
  airlineColor: string;
  flightNumber: string;
  registration: string;
}

const downloadButtonClassName =
  'my-[30px] bg-primary h-44 w-[75%] rounded-4 mx-auto flex items-center justify-center';

const downloadButtonTextClass = 'font-body-text font-bold text-white text-14';

const textColorClassName = 'text-primary text-14 font-body-text font-bold';

const BriefingPackage = ({
  handleCloseBriefingPackage,
  flightId,
  airlineColor,
  flightNumber,
  registration,
}: IBriefingPackage) => {
  const dispatch = useDispatch();
  const [selectedFileType, setSelectedFileType] = useState<string>('');
  const { loading: isLoadingBriefingPackage, getBriefingPackageFile } =
    useGetBriefingPackageLazy();

  const handleFileRequest = async (fileType: string) => {
    setSelectedFileType(fileType);
    const fileBase64 = await getBriefingPackageFile(flightId, fileType);

    if (fileBase64?.status === true && fileBase64?.file) {
      const fileName = createBriefingPackageFileName(
        flightId,
        registration === '' ? NO_REGISTRATION_FILE_NAME : registration
      );

      const downloadFile = downloadFileObject(
        fileBase64?.file,
        fileType,
        fileName
      );

      if (!downloadFile) {
        dispatch(addError(DOWNLOAD_BRIEFING_PACKAGE_ERROR));
      }
    } else {
      dispatch(addError(DOWNLOAD_BRIEFING_PACKAGE_ERROR));
    }
  };

  return (
    <div className="h-full overflow-y-clip flex flex-col relative dark:bg-grey-90">
      <MenuHeader
        className="tablet:hidden laptop:hidden desktop:hidden"
        themeColor={classNames(airlineColor, 'dark:bg-grey-90')}
        onMenuClick={() => {
          dispatch(setIsMenuOpen(true));
        }}
        hasBackArrow
        onBackArrowClick={() => {
          dispatch(setIsBriefingPackageOpen(false));
        }}
      />
      <div className="p-16 flex justify-between content-center items-center border-b-grey-12 border-b-1 pb-16">
        <div>
          <div className="text-14 text-primary dark:text-white font-body-text uppercase font-semibold">
            {BRIEFING_PACKAGE.TITLE}
          </div>
          <div className="text-12 text-primary dark:text-white font-bold font-body-text">
            {flightNumber}
          </div>
        </div>
        <Button
          text={BRIEFING_PACKAGE.CANCEL}
          className="w-[110px] bg-grey-12 dark:bg-white rounded-4 h-44 flex items-center justify-center"
          textClassName="text-14 !text-primary font-body-text leading-[18px]"
          onClick={handleCloseBriefingPackage}
        />
      </div>
      <div className="pl-[15px] overflow-y-auto">
        <div className="flex flex-row my-[50px]">
          <div className="place-content-start">
            <Icon width={50} height={50} variant="info" />
          </div>

          <Title
            title={BRIEFING_PACKAGE.INFO_TEXT}
            titleColorClass={
              'text-primary text-14 font-body-text leading-normal'
            }
            className="px-[20px]"
          />
        </div>
        <Title
          title={BRIEFING_PACKAGE.DIGITAL_BRIEFING}
          titleColorClass={textColorClassName}
        />

        <Button
          text={
            isLoadingBriefingPackage && selectedFileType === FILE_TYPES.EFF ? (
              <LoadingSpinner width={25} height={25} />
            ) : (
              BRIEFING_PACKAGE.DOWNLOAD
            )
          }
          className={downloadButtonClassName}
          textClassName={downloadButtonTextClass}
          onClick={() => handleFileRequest(FILE_TYPES.EFF)}
          disabled={isLoadingBriefingPackage}
        />
        <Title
          title={BRIEFING_PACKAGE.PAPER_BRIEFING}
          titleColorClass={textColorClassName}
        />

        <Button
          text={
            isLoadingBriefingPackage && selectedFileType === FILE_TYPES.PDF ? (
              <LoadingSpinner width={25} height={25} />
            ) : (
              BRIEFING_PACKAGE.DOWNLOAD
            )
          }
          className={downloadButtonClassName}
          textClassName={downloadButtonTextClass}
          onClick={() => handleFileRequest(FILE_TYPES.PDF)}
          disabled={isLoadingBriefingPackage}
        />
        {false && (
          <>
            <Title
              title={BRIEFING_PACKAGE.HIL_BRIEFING}
              titleColorClass={textColorClassName}
            />
            <Button
              text={
                isLoadingBriefingPackage &&
                selectedFileType === FILE_TYPES.HIL ? (
                  <LoadingSpinner width={25} height={25} />
                ) : (
                  BRIEFING_PACKAGE.DOWNLOAD
                )
              }
              className={downloadButtonClassName}
              textClassName={downloadButtonTextClass}
              onClick={() => handleFileRequest(FILE_TYPES.HIL)}
              disabled={isLoadingBriefingPackage}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BriefingPackage;
