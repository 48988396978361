import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_USER_SETTINGS } from '../../graphql/settings/settings.queries';
import { Query, UserSettingsOutput } from '../generated/graphql';
import { IDataResponse } from '../types';

interface IGetUserSettingsParams {
  skip?: boolean;
}

export const useGetUserSettings = (
  options: IGetUserSettingsParams | null = null
): IDataResponse<UserSettingsOutput> => {
  const { data, loading, error } = useQuery<Pick<Query, 'getUserSettings'>>(
    GET_USER_SETTINGS,
    options ?? undefined
  );

  return { data: data?.getUserSettings ?? null, loading, error };
};

interface IGerUSerSettingsLazyResponse
  extends IDataResponse<UserSettingsOutput> {
  getUserSettings: () => Promise<UserSettingsOutput | null>;
}

export const useGetUserSettingsLazy = (): IGerUSerSettingsLazyResponse => {
  const [get, { data, loading, error }] =
    useLazyQuery<Pick<Query, 'getUserSettings'>>(GET_USER_SETTINGS);

  const getUserSettings = async () => {
    const result = await get();

    return result.data?.getUserSettings ?? null;
  };

  return {
    data: data?.getUserSettings ?? null,
    loading,
    error,
    getUserSettings,
  };
};
