import { useLazyQuery, useQuery } from '@apollo/client';
import { BriefingPackageOutput, Query } from '../generated/graphql';
import { IDataResponse } from '../types';
import { GET_BRIEFING_PACKAGE } from '../../graphql/briefingPackage/briefingPackage.queries.ts';

interface IBriefingPackageLazy extends IDataResponse<BriefingPackageOutput> {
  getBriefingPackageFile: (
    flightId: string,
    type: string
  ) => Promise<BriefingPackageOutput | null>;
}

export const useGetBriefingPackage = (
  flightId: string,
  type: string
): IDataResponse<BriefingPackageOutput> => {
  const { data, loading, error } = useQuery<Pick<Query, 'briefingPackage'>>(
    GET_BRIEFING_PACKAGE,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        flightId,
        type,
      },
    }
  );

  return { data: data?.briefingPackage ?? null, loading, error };
};

export const useGetBriefingPackageLazy = (): IBriefingPackageLazy => {
  const [onGetFile, { data, loading, error }] =
    useLazyQuery<Pick<Query, 'briefingPackage'>>(GET_BRIEFING_PACKAGE);

  const getBriefingPackageFile = async (flightId: string, type: string) => {
    const result = await onGetFile({
      variables: {
        flightId,
        type,
      },
    });

    return result?.data?.briefingPackage ?? null;
  };

  return {
    data: data?.briefingPackage ?? null,
    loading,
    error,
    getBriefingPackageFile,
  };
};
