import { gql } from '@apollo/client';

export const GET_BRIEFING_PACKAGE = gql`
  query BriefingPackage($flightId: String!, $type: String!) {
    briefingPackage(flightId: $flightId, type: $type) {
      file
      status
      errors {
        code
        message
        description
      }
    }
  }
`;
