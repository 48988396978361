import moment from 'moment';

export function downloadFileObject(
  base64: string,
  fileType: string,
  fileName: string
) {
  base64 = `data:application/${fileType.toLowerCase()};base64,${base64}`;
  if (base64) {
    const downloadLink = document.createElement('a');
    downloadLink.href = base64;
    downloadLink.download = `${fileName}.${fileType}`;
    downloadLink.click();
    return true;
  } else {
    return false;
  }
}

export function createBriefingPackageFileName(
  flightNumber: string,
  registration: string
) {
  const splittedId = flightNumber.split('-');
  const flightName =
    splittedId?.[0]?.substring(8, splittedId?.[0]?.length) +
    splittedId[1]?.replace(/^0+/, '');
  const departureTime = splittedId?.[0]?.substring(2, 8) ?? '';
  const downloadDate = moment().utc().format('YYMMDDHHMMSS');

  return `${registration}_${flightName ?? ''}_${departureTime}_${
    splittedId[2] ?? ''
  }_${splittedId[3] ?? ''}_F${downloadDate}`;
}
