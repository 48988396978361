import React, { useState } from 'react';
import moment from 'moment';
import Title from '../Title/Title';
import NotificationHistoryList from './NotificationHistoryList/NotificationHistoryList';
import {
  ADMIN_NOTIFICATIONS,
  NEWSFEED_NO_NOTIFICATIONS as NO_NOTIFICATIONS,
} from '../../utils/constants';
import {
  NotificationModalVariant,
  renderNotificationModal,
} from '../../utils/notifications/notificationModalUtil';
import { useCreateNotification } from '../../utils/hooks/useCreateNotification';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { useGetAllNotifications } from '../../utils/hooks/useGetAllNotifications';
import { selectRoles } from '../../redux/reducers/settingsReducer';
import { useSelector } from 'react-redux';
import { getNotificationTypesFromRoles } from '../../utils/notifications/notificationsUtil';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useUpdateNotification } from '../../utils/hooks/useUpdateNotification';
import { useDeleteNotification } from '../../utils/hooks/useDeleteNotification';
import { GET_ALL_NOTIFICATIONS } from '../../graphql/notifications/notifications.quries';
import {
  NotificationCategory,
  NotificationType,
  Permission,
} from '../../utils/generated/graphql';
import useCheckPermissions, {
  CheckType,
} from '../../utils/hooks/useCheckPermissions';
import { isNotificationInputValid } from '../../utils/helpers';
import ReloadButton from '../CustomButtons/ReloadButton/ReloadButton';
import useNetworkStatus from '../../utils/hooks/useNetworkStatus';
import { ISubmitFields } from './NotificationForm/NotificationForm';

export interface IModalData {
  variant: NotificationModalVariant;
  notificationId?: string;
}

export const NOTIFICATION_FORM_ID = 'notification-form';

const getNotificationModalTitle = (
  variant: NotificationModalVariant | null
) => {
  if (variant === NotificationModalVariant.CREATE) return 'Create';
  if (variant === NotificationModalVariant.EDIT) return 'Edit';
  if (variant === NotificationModalVariant.DELETE) return 'Delete';
};

const AdminNotificationCenter = () => {
  const reloadButtonClass =
    'mobile:relative mobile:left-0 w-fit absolute mobile:top-[10px] top-24 z-40 mobile:z-0 left-[63px] right-0 mx-auto';

  const [modalData, setModalData] = useState<IModalData | null>(null);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const roles = useSelector(selectRoles);
  const { data, loading, refetchNotifications } = useGetAllNotifications(
    getNotificationTypesFromRoles(roles)
  );
  const notificationToBeUpdated = data?.find(
    (notification) => notification._id === modalData?.notificationId
  );

  const isOnline = useNetworkStatus();

  const { onCreateNotification, loading: loadingCreate } =
    useCreateNotification({
      refetchQueries: [GET_ALL_NOTIFICATIONS],
    });

  const { onUpdateNotification, loading: loadingUpdate } =
    useUpdateNotification({
      refetchQueries: [GET_ALL_NOTIFICATIONS],
    });

  const { onDeleteNotification } = useDeleteNotification({
    refetchQueries: [GET_ALL_NOTIFICATIONS],
  });

  const handleCreateNotification = async (elem: ISubmitFields) => {
    const {
      e,
      startDate,
      endDate,
      senderRole,
      stations,
      targetRoles,
      topic,
      type,
      subtitle,
      description,
    } = elem;
    e.preventDefault();

    const sendDateObject = moment(startDate);

    const deleteDateObject = moment(endDate);

    const sendIsoString = sendDateObject.toISOString();
    const deleteIsoString = deleteDateObject.toISOString();

    canSubmit &&
      (await onCreateNotification({
        type: type ?? NotificationType.STATION,
        topic,
        category: NotificationCategory.GENERAL,
        description,
        subtitle: subtitle ?? '',
        details: {
          stations,
          targetRoles: targetRoles,
          senderRole,
          schedule: {
            sendDate: sendIsoString ?? '',
            deleteDate: deleteIsoString ?? '',
          },
        },
      }));

    canSubmit && setModalData(null);
  };

  const handleUpdateNotification = async (elem: ISubmitFields) => {
    const { e, startDate, endDate, stations, type, subtitle, description } =
      elem;
    e.preventDefault();

    const sendDateObject = moment(startDate);
    const deleteDateObject = moment(endDate);

    const sendIsoString = sendDateObject.toISOString();
    const deleteIsoString = deleteDateObject.toISOString();
    const stationLenghtValidity =
      type === NotificationType.STATION ? stations && stations.length : true;

    const shouldUpdate =
      stationLenghtValidity &&
      isNotificationInputValid(description) &&
      isNotificationInputValid(subtitle);

    shouldUpdate &&
      (await onUpdateNotification({
        _id: notificationToBeUpdated?._id ?? '',
        description: description,
        subtitle: subtitle ?? '',
        details: {
          stations,
          schedule: {
            sendDate: sendIsoString ?? '',
            deleteDate: deleteIsoString ?? '',
          },
        },
      }));
    shouldUpdate && setModalData(null);
  };

  const handleDeleteNotification = () => {
    onDeleteNotification(notificationToBeUpdated?._id ?? '');
    setModalData(null);
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  const renderList = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    if (data?.length) {
      return (
        <NotificationHistoryList
          notifications={data}
          setModalData={setModalData}
        />
      );
    }

    return (
      <h1 className="text-center text-primary pb-32 mobile:pt-[24px]">
        {NO_NOTIFICATIONS}
      </h1>
    );
  };
  const hasCreateNotificationPermissions = useCheckPermissions(
    [
      Permission.CREATE_ALL_NOTIFICATIONS,
      Permission.CREATE_STATION_NOTIFICATIONS,
      Permission.CREATE_ROLE_NOTIFICATIONS,
    ],
    CheckType.PARTIAL
  );

  return (
    <>
      <div className={reloadButtonClass}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          isOnline && <ReloadButton refetchFunctions={[refetchNotifications]} />
        )}
      </div>
      <div className="bg-white rounded-8 px-24 pt-24 mt-24 pb-24 dark:bg-grey-90">
        <div className="flex justify-between items-center mb-6 ">
          <Title
            title={ADMIN_NOTIFICATIONS.NOTIFICATION_HISTORY}
            titleColorClass="text-primary text-14 font-head-light"
          />
          {hasCreateNotificationPermissions && (
            <Button
              onClick={() =>
                setModalData({
                  variant: NotificationModalVariant.CREATE,
                  notificationId: undefined,
                })
              }
              className="bg-primary dark:bg-white p-[8px] rounded-4 font-head-bold flex flex-row justify-center items-center flex-nowrap"
              text={ADMIN_NOTIFICATIONS.NEW_NOTIFICATION}
              textClassName="text-white dark:text-primary"
            />
          )}
          <Modal
            formId={NOTIFICATION_FORM_ID}
            title={getNotificationModalTitle(modalData?.variant ?? null) ?? ''}
            showModal={!!modalData}
            setShowModal={setModalData}
            onSubmit={
              modalData?.variant === NotificationModalVariant.DELETE
                ? handleDeleteNotification
                : undefined
            }
            onCancel={handleCloseModal}
            isSubmitLoading={
              modalData?.variant === NotificationModalVariant.CREATE
                ? loadingCreate
                : modalData?.variant === NotificationModalVariant.EDIT
                ? loadingUpdate
                : false
            }
            canSubmit={canSubmit}
            className="mb-auto">
            {renderNotificationModal(
              modalData?.variant ?? null,
              notificationToBeUpdated,
              handleCreateNotification,
              handleUpdateNotification,
              setCanSubmit
            )}
          </Modal>
        </div>
        {renderList()}
      </div>
    </>
  );
};

export default AdminNotificationCenter;
